import { YearsApi } from '@extend/paywall-api-javascript';
import { getApiClient } from '../../api/client';
import { to_ra_query_result } from '../../utils/result-converters';

const yearsProvider = {
	getList: async params => {
		const { filter } = params;
		var api = new YearsApi(getApiClient());
		var years = await api.query(filter);
		var yearEntities = years.map(year => ({ id: year }));
		var queryResult = to_ra_query_result(yearEntities);
		return queryResult;
	}
};

export default yearsProvider;
