import IconDownload from '@mui/icons-material/Download';
import { Button } from 'react-admin';
import { useDownloadFile } from '../hooks/useDownloadFile';

export const DownloadButton = ({ url, mimeType, parameters, fileName, label, hideIcon, ...props }) => {
	const download = useDownloadFile({ url, mimeType, parameters, fileName });
	return (
		<Button {...props} onClick={download} label={label ? label : 'Download'} variant="outlined">
			{hideIcon ? null : <IconDownload />}
		</Button>
	);
};
