import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import localeEnUs from 'date-fns/locale/en-US';

const adapterHolder = { adapter: null };
export const getDateAdapter = () => adapterHolder.adapter;
export const useDateAdapter = () => adapterHolder.adapter;

class WisdropAdminAdpater extends AdapterDateFns {
	constructor(...args) {
		super(...args);
		adapterHolder.adapter = this;
	}
}

export const WisdropLocalizationProvider = ({ children }) => {
	return (
		<LocalizationProvider adapterLocale={localeEnUs} dateAdapter={WisdropAdminAdpater}>
			{children}
		</LocalizationProvider>
	);
};
