import { CustomersApi } from '@extend/paywall-api-javascript';
import { getApiClient } from '../../api/client';
import { overrides } from '../../utils/override';
import { to_ra_data_dictionary_result, to_ra_data_item_result, to_ra_data_result } from '../../utils/result-converters';

const customerMediaAccessibilityProvider = {
	getMany: ({ ids }) => {
		if (ids.length === 0) return Promise.resolve({ data: [] });
		const [, customerId] = overrides.destructureId(ids[0]);
		const mapper = overrides.createMapper(customerId, 'media_id');
		return new CustomersApi(getApiClient())
			.getMediaAccessibility(customerId, {
				CustomerGetMediaAccessibilityParams: {
					media_ids: ids.map(id => overrides.destructureId(id)[0])
				}
			})
			.then(mapper)
			.then(to_ra_data_result);
	},

	getOne: ({ id }) => {
		const [mediaId, customerId] = overrides.destructureId(id);
		const mapper = overrides.createMapper(customerId, 'media_id');
		return new CustomersApi(getApiClient())
			.getMediaAccessibility(customerId, { CustomerGetMediaAccessibilityParams: { media_ids: [mediaId] } })
			.then(mapper)
			.then(to_ra_data_item_result);
	},

	update: ({ id, data: { property, value } = {} }) => {
		const [mediaId, customerId] = overrides.destructureId(id);
		const mapper = overrides.createMapper(customerId, 'media_id');
		let api = new CustomersApi(getApiClient());
		return api
			.changeMediaAccessibility(customerId, {
				CommandCustomerChangeMediaAccessibility: { property, value, ids: [mediaId] }
			})
			.then(mapper)
			.then(to_ra_data_item_result);
	},

	updateMany: ({ ids, data: { customerId, property, value } = {} }) => {
		if (ids.length === 0) return Promise.resolve({ data: [] });
		const mapper = overrides.createMapper(customerId, 'media_id');
		let api = new CustomersApi(getApiClient());
		return api
			.changeMediaAccessibility(customerId, { CommandCustomerChangeMediaAccessibility: { property, value, ids } })
			.then(mapper)
			.then(result => to_ra_data_dictionary_result(ids, result));
	}
};

export default customerMediaAccessibilityProvider;
