import { FILE } from '@extend/paywall-api/lib/resource-types';
import ErrorIcon from '@mui/icons-material/Error';
import { RecordContext, ResourceContext, useGetOne } from 'react-admin';
import SmallLinearProgress from '../small-linear-progress';

export const FileLoader = ({ fileId, children, meta, options }) => {
	const getOneResult = useGetOne(FILE, { id: fileId, meta }, { enable: !!fileId, ...options });
	const file = !fileId ? null : getOneResult.data;
	if (!fileId) return null;
	if (file)
		return (
			<ResourceContext.Provider value={FILE}>
				<RecordContext.Provider value={file}>
					{typeof children === 'function' ? children({ file, ...getOneResult }) : children}
				</RecordContext.Provider>
			</ResourceContext.Provider>
		);
	const { isLoading, isFetching, isRefetching, isError } = getOneResult;
	if (isLoading || isFetching || isRefetching) return <SmallLinearProgress />;
	if (isError) return <ErrorIcon />;
	return null;
};
