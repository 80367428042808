import { Children, cloneElement, useCallback } from 'react';
import { useCreate, useResourceContext } from 'react-admin';
import { useConvertToRef } from '../../hooks/useConvertToRef';

/**
 * Provides a callback for quick creation of a resource. Quick creation is the creation of a resource based only on a single text value
 * Usefull for quick creating tag-type resources. You must provide the mapFilter function that is responsible for converting the
 * text value into a resource record object ready to be sent to the 'create' method of the resource's data adapter.
 * The callback generated is passed down to the single child element as 'onCreate' prop.
 * Must be used with an AutocompleteInput or AutocompleteArrayInput child element
 */
export const WithQuickCreate = ({ resource: _resource, mapFilter, children, defaultValues, enable, ...props }) => {
	const handleCreate = useQuickCreate(_resource, mapFilter, defaultValues);
	const onlyChild = Children.only(children);
	return onlyChild
		? cloneElement(onlyChild, {
				...props,
				onCreate: enable === undefined || !!enable ? handleCreate : undefined,
				...onlyChild.props
		  })
		: null;
};

/**
 * Provides a callback for quick creation of a resource. Quick creation is the creation of a resource based only on a single text value
 * Usefull for quick creating tag-type resources. You must provide the mapFilter function that is responsible for converting the
 * text value into a resource record object ready to be sent to the 'create' method of the resource's data adapter.
 */
export const useQuickCreate = (resource, mapFilter, defaultValues) => {
	const rsrc = useResourceContext({ resource: resource });
	const [create] = useCreate();
	const defValRef = useConvertToRef(defaultValues);
	const mapFilterRef = useConvertToRef(mapFilter);
	return useCallback(
		filter => {
			let data;
			if (typeof mapFilterRef.current === 'string') data = { ...defValRef.current, [mapFilterRef.current]: filter };
			else if (typeof mapFilterRef.current === 'function') data = mapFilterRef.current(filter, defValRef.current);
			else data = { ...defValRef.current };
			return create(rsrc, { data }, { returnPromise: true });
		},
		[create, defValRef, mapFilterRef, rsrc]
	);
};
