import { queryClient } from './app-query-client';
import authProvider from './auth/provider';
import { BLOBS } from './resources/blobs/name';
import DownloadImageCache from './utils/download-image-cache';

export const appImageDownloader = new DownloadImageCache(
	() => authProvider.getAuthToken(),
	url => {
		if (url) queryClient.invalidateQueries([BLOBS, 'getOne', { id: url }]);
		else queryClient.invalidateQueries([BLOBS]);
	}
);
