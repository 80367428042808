import { AdScoresApi, EntitiesApi } from '@extend/paywall-api-javascript';
import { getApi } from '../../api/client';
import { from_ra_query, prepareForPost, to_ra_data_result, to_ra_query_result } from '../../utils/result-converters';
import { alphanumericCollator, uploadFilesToBatch } from '../../utils/uploadFilesToBatch';

const adScoresProvider = {
	getManyReference: params => {
		var actualParams = { ...params, [params.target]: params.id };
		return getApi(AdScoresApi)
			.query({ AdScoreQuery: from_ra_query(actualParams) })
			.then(to_ra_query_result);
	},
	getList: params =>
		getApi(AdScoresApi)
			.query({ AdScoreQuery: from_ra_query(params) })
			.then(to_ra_query_result),
	getMany: ({ ids }) => getApi(AdScoresApi).getMany({ request_body: ids }).then(to_ra_data_result),
	getOne: async ({ id, meta }) => {
		const api = getApi(AdScoresApi);
		let res = await api.get(id);
		if (meta?.edit) {
			const { available_in_country_ids, tag_ids } = await api.getOneToManyReferences(id);
			// get thumbnail
			const eApi = getApi(EntitiesApi);
			const atts = await eApi.getAttachments(id);

			res = {
				...res,
				attachments: (atts || []).reduce((v, att) => {
					v[att.identifier] = att.id;
					return v;
				}, {}),
				thumbnail_id: atts['thumbnail']?.id,
				available_in_country_ids: (available_in_country_ids || []).map(i => i.id || i),
				tag_ids: (tag_ids || []).map(i => i.id || i)
			};
		}
		return to_ra_data_result(res);
	},

	create: ({ data }) =>
		getApi(AdScoresApi)
			.create({ CommandCreateAdScore: prepareForPost(data) })
			.then(to_ra_data_result),
	update: ({ previousData, data, id }) => {
		delete previousData.attachments;
		return getApi(AdScoresApi)
			.update(id, { CommandUpdateAdScore: prepareForPost({ ...previousData, ...data }) })
			.then(to_ra_data_result);
	},
	delete: ({ id }) => getApi(AdScoresApi).remove(id).then(to_ra_data_result),
	deleteMany: ({ ids }) => getApi(AdScoresApi).removeMany(ids).then(to_ra_data_result),
	addAdscoreBatchSlides: params => handleBatchUploadAdScoreSlides(params),
	deleteAllSlides: ({ id }) => getApi(AdScoresApi).deleteAllSlides(id).then(to_ra_data_result)
};

const handleBatchUploadAdScoreSlides = async ({ id, files, progressReset, progressUpdate }) => {
	var batch_id = await uploadFilesToBatch({ files, progressReset, progressUpdate });

	var slideInfo = files
		.map(f => f.rawFile.name)
		.sort(alphanumericCollator.compare)
		.map(name => ({ name: name }));

	var adscoresApi = getApi(AdScoresApi);
	var result = await adscoresApi.addSlidesFromBatch(id, {
		BatchCreateAdScoreSlideCommand: {
			adscore_id: id,
			batch_id: batch_id,
			slides: slideInfo
		}
	});
	return to_ra_data_result(result);
};

export default adScoresProvider;
