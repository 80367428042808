import { COMPANIES } from '@extend/paywall-api/lib/resource-types';
import IconLabel from '@mui/icons-material/Label';
import { lazy } from 'react';
import { Resource } from 'react-admin';

const CompaniesIndex = lazy(() => import(/* webpackChunkName: "companies-list" */ './list.js'));
const CompanyCreator = lazy(() => import(/* webpackChunkName: "companies-creator" */ './creator.js'));
const CompanyEditor = lazy(() => import(/* webpackChunkName: "companies-editor" */ './editor.js'));

const getCompaniesResource = perms => (
	<Resource
		key={COMPANIES}
		name={COMPANIES}
		options={{ label: 'Companies' }}
		list={CompaniesIndex}
		edit={CompanyEditor}
		create={CompanyCreator}
		icon={IconLabel}
	/>
);
export default getCompaniesResource;
