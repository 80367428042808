import { CUSTOMERS } from '@extend/paywall-api/lib/resource-types';
import IconDomain from '@mui/icons-material/Domain';
import { lazy } from 'react';
import { Resource } from 'react-admin';
import CustomerCreator from './creator';
import { customerDisplayName } from './utils/display-name';

const CustomersIndex = lazy(() => import(/* webpackChunkName: "customer-list" */ './list'));
const CustomerEditor = lazy(() => import(/* webpackChunkName: "customer-editor" */ './editor'));

const getCustomersResource = perms => (
	<Resource
		key={CUSTOMERS}
		name={CUSTOMERS}
		options={{ label: 'Clients' }}
		list={CustomersIndex}
		create={CustomerCreator}
		edit={CustomerEditor}
		icon={IconDomain}
		recordRepresentation={customerDisplayName}
	/>
);

export default getCustomersResource;
