import { styled } from '@mui/material';
import { CreateButton, FilterButton, TopToolbar } from 'react-admin';
import { ListExportExcelButton } from './export-button';

const WdActionsToolbar = styled(TopToolbar)({ 'flexWrap': 'nowrap', '& > *': { marginLeft: 8 } });

export const DefaultActions = ({
	showCreateButton = true,
	showFilterButton = true,
	excelExport,
	createLabel,
	hasCreate,
	...props
}) => (
	<WdActionsToolbar {...props}>
		{showFilterButton ? <FilterButton /> : null}
		{showCreateButton ? <CreateButton size="medium" label={createLabel} variant="contained" /> : null}
		{excelExport ? (
			<ListExportExcelButton
				size="medium"
				url={excelExport.url}
				fileName={excelExport.fileName}
				label={excelExport.label}
				mimeType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			/>
		) : null}
	</WdActionsToolbar>
);
