import { BRANDS, COMPANIES, COUNTRIES, INDUSTRIES, TAGS } from '@extend/paywall-api/lib/resource-types';
import { CardContent, Stack } from '@mui/material';
import {
	AutocompleteArrayInput,
	AutocompleteInput,
	FileField,
	NumberInput,
	ReferenceArrayInput,
	ReferenceInput,
	TextInput
} from 'react-admin';
import { MuiDatePickerInput } from '../../components/date-picker-input';
import { UploadedImageField } from '../../components/fields/uploaded-image-field';
import { FieldWatcher } from '../../components/fields/watcher';
import { WdForm } from '../../components/form';
import { WithQuickCreate } from '../../components/form/quick-create';

import { FormToolbar } from '../../components/form/toolbar';
import { ReferenceFileInput } from '../../components/inputs/reference-image-input';
import { validateForm } from './editor/validate';

const fieldChangeHandlers = {
	company_id: ({ setValue }) => setValue('brand_id', null)
};

export const AdScoreForm = () => {
	return (
		<WdForm validate={validateForm} onValueChange={fieldChangeHandlers}>
			<CardContent>
				<Stack spacing={4} direction="row" justifyContent="stretch" sx={{ width: '100%' }}>
					<Stack flex="1 1 0">
						<ReferenceInput
							perPage={1000}
							source="industry_id"
							fullWidth
							reference={INDUSTRIES}
							sort={{ field: 'title' }}>
							<AutocompleteInput getOptionLabel={r => r.title} optionText="title" label="Industry" />
						</ReferenceInput>
						<ReferenceInput
							perPage={1000}
							source="company_id"
							allowEmpty
							fullWidth
							reference={COMPANIES}
							sort={{ field: 'title' }}>
							<WithQuickCreate mapFilter="title">
								<AutocompleteInput optionText="title" label="Company" />
							</WithQuickCreate>
						</ReferenceInput>
						<FieldWatcher name="company_id">
							{company_id => (
								<ReferenceInput
									perPage={1000}
									source="brand_id"
									reference={BRANDS}
									allowEmpty
									fullWidth
									filter={{ force_company_id: true, company_id }}
									sort={{ field: 'title' }}>
									<WithQuickCreate mapFilter="title" defaultValues={{ company_id }} enable={!!company_id}>
										<AutocompleteInput emptyValue="" optionText="title" label="Brand" />
									</WithQuickCreate>
								</ReferenceInput>
							)}
						</FieldWatcher>
						<TextInput source="campaign" label="Campaign" fullWidth />
						<ReferenceInput
							perPage={1000}
							source="country_id"
							defaultValue="GR"
							reference={COUNTRIES}
							sort={{ field: 'name' }}>
							<AutocompleteInput optionText="name" label="Country" />
						</ReferenceInput>
						<TextInput source="video_link" label="Video Link" fullWidth />
						<ReferenceArrayInput sort={{ field: 'name' }} source="tag_ids" fullWidth reference={TAGS}>
							<WithQuickCreate mapFilter="name">
								<AutocompleteArrayInput
									emptyValue={[]}
									optionText="name"
									label="Tags"
									TextFieldProps={{ placeholder: 'Start typing to add new tag...' }}
								/>
							</WithQuickCreate>
						</ReferenceArrayInput>
					</Stack>
					<Stack flex="1 1 0">
						<ReferenceArrayInput
							source="available_in_country_ids"
							reference={COUNTRIES}
							perPage={1000}
							sort={{ field: 'name' }}>
							<AutocompleteArrayInput
								emptyValue={[]}
								optionText="name"
								label="Available in Countries"
								fullWidth
							/>
						</ReferenceArrayInput>
						<TextInput source="agency" label="Agency" fullWidth />
						<MuiDatePickerInput source="release_date" label="On Air" />
						<NumberInput source="length" label="Length" step={1} />
						<ReferenceFileInput label="Thumbnail" source="attachments.thumbnail" allowEmpty>
							<UploadedImageField />
						</ReferenceFileInput>
						<ReferenceFileInput
							label="Report Pdf"
							source="attachments.pdf_presentation"
							allowEmpty
							accept={['application/pdf', 'application/x-pdf']}>
							<FileField source="file_url" title="name" />
						</ReferenceFileInput>
					</Stack>
				</Stack>
			</CardContent>
			<FormToolbar cancel />
		</WdForm>
	);
};
