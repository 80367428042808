const validate = values => {
	const errors = {};
	['email', 'name', 'last_name', 'is_admin', 'is_suspended', 'is_counted'].forEach(field => {
		const val = values[field];
		if (val == null || val === '') errors[field] = 'Required';
	});
	return errors;
};

export default validate;
