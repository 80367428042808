import { CountriesApi } from '@extend/paywall-api-javascript';
import { getApiClient } from '../../api/client';
import { from_ra_query, to_ra_data_result, to_ra_query_result } from '../../utils/result-converters';

const countriesProvider = {
	getList: async params => {
		let { filter } = params;
		let { q, withCreate = false, ...rest } = filter;
		params.filter = { q, ...rest };

		var result = to_ra_query_result(
			await new CountriesApi(getApiClient()).query({ CountryQuery: from_ra_query(params) })
		);

		if (withCreate && q) {
			var existing = result.data.find(d => d.name.toLowerCase() === q.toLowerCase());
			if (!existing) result.data = [{ id: '-', name: `Create country ${q}`, newItem: q }, ...result.data];
		}
		return result;
	},

	getMany: ({ ids }) => new CountriesApi(getApiClient()).getMany({ request_body: ids }).then(to_ra_data_result),

	getOne: ({ id }) => new CountriesApi(getApiClient()).get(id).then(to_ra_data_result),

	getManyReference: ({ id, target, filter, ...restParams }) =>
		new CountriesApi(getApiClient())
			.query({
				CountryQuery: from_ra_query({
					...restParams,
					filter: {
						...filter,
						[target]: id
					}
				})
			})
			.then(to_ra_query_result)
};

export default countriesProvider;
