import { memo } from 'react';
import { useRecordContext } from 'react-admin';
import { useMemo } from 'react';

export const getMediaSmallTitle = record => {
	return record ? (record.season_count > 1 ? `${record.title} - s${record.season_number}` : record.title) : '';
};

export const MediaSmallTitle = memo(props => {
	const record = useRecordContext(props);
	var title = useMemo(() => getMediaSmallTitle(record), [record]);
	return title ? <span>{title}</span> : null;
});
