import { styled } from '@mui/material';
import { Datagrid } from 'react-admin';

const prefix = 'WdDataGrid';

/** Wisdrop data grid. Defines some extra styles */
export const WdDataGrid = styled(Datagrid, {
	name: prefix,
	slot: 'Root',
	overridesResolver: (props, styles) => styles.root
})({
	'& .align-center': { textAlign: 'center' },
	'& .align-right': { textAlign: 'right' },
	'& .align-left': { textAlign: 'left' },
	'& .align-justify': { textAlign: 'justify' },
	'& .no-wrap': { whiteSpace: 'nowrap' },
	'& .no-y-padding': { paddingTop: 0, paddingBottom: 0 },
	'& .no-x-padding': { paddingLeft: 0, paddingRight: 0 },
	'& .no-padding': { paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 0, padding: 0 },
	'& .no-link': { 'textDecoration': 'none', '& *': { textDecoration: 'none' } }
});
