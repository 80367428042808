import { FormHelperText, Stack } from '@mui/material';
import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import { Button, InputHelperText, Labeled, sanitizeInputRestProps, useInput, useResourceContext } from 'react-admin';
import { FileUploadDialog } from '../../resources/files/upload-modal';
import { FileLoader } from '../fields/file-loader';

export const ReferenceFileInput = ({
	accept,
	children,
	className,
	helperText,
	inputProps: inputPropsOptions,
	maxSize,
	minSize,
	label,
	options = {},
	onRemove: onRemoveProp,
	placeholder,
	resource: _resource,
	source,
	validate,
	validateFileRemoval,
	allowEmpty,
	...props
}) => {
	const [dialogOpen, setDialogOpen] = useState(false);
	const resource = useResourceContext({ resource: _resource });
	const {
		id,
		field: { onChange, onBlur, value },
		fieldState: { isTouched, error },
		formState: { isSubmitted },
		isRequired
	} = useInput({ source, validate, ...props });

	const handleClose = useCallback(() => setDialogOpen(false), []);
	const handleOpen = useCallback(() => setDialogOpen(true), []);
	const mutationOptions = useMemo(
		() => ({
			onSuccess: file => {
				onChange(file.id);
				onBlur();
				handleClose();
			}
		}),
		[handleClose, onBlur, onChange]
	);
	const inputProps = useMemo(
		() => ({ accept: accept ?? 'image/*', ...(inputPropsOptions || {}) }),
		[accept, inputPropsOptions]
	);

	const handleClear = useCallback(
		e => {
			e?.preventDefault?.();
			onChange(null);
			onBlur();
		},
		[onBlur, onChange]
	);

	const hasError = (isTouched || isSubmitted) && !!error;

	return (
		<>
			<Labeled
				htmlFor={id}
				label={label}
				className={clsx('ra-input', `ra-input-${source}`, className)}
				source={source}
				resource={resource}
				isRequired={isRequired}
				color={hasError ? 'error' : undefined}
				{...sanitizeInputRestProps(props)}>
				<>
					<Stack direction="row" spacing={1}>
						<FileLoader fileId={value}>{children}</FileLoader>
						<Button label={!!value ? 'Change' : 'Upload'} onClick={handleOpen} />
						{!!value && allowEmpty ? <Button label="Delete" onClick={handleClear} /> : null}
					</Stack>
					<FormHelperText error={hasError}>
						<InputHelperText touched={isTouched || isSubmitted} error={error?.message} helperText={helperText} />
					</FormHelperText>
				</>
			</Labeled>
			<FileUploadDialog
				mutationOptions={mutationOptions}
				onClose={handleClose}
				open={dialogOpen}
				inputProps={{ accept, ...inputProps }}
			/>
		</>
	);
};
