import { SubscriptionDistributionSettingsApi } from '@extend/paywall-api-javascript';
import { getApiClient } from '../../api/client';
import { from_ra_query, prepareForPost, to_ra_data_result, to_ra_query_result } from '../../utils/result-converters';

const subscriptionDistributionSettingsProvider = {
	getList: params =>
		new SubscriptionDistributionSettingsApi(getApiClient())
			.query({ SubscriptionDistributionPermissionQuery: from_ra_query(params) })
			.then(to_ra_query_result),
	getMany: ({ ids }) =>
		new SubscriptionDistributionSettingsApi(getApiClient()).getMany({ request_body: ids }).then(to_ra_data_result),
	getOne: ({ id }) => new SubscriptionDistributionSettingsApi(getApiClient()).get(id).then(to_ra_data_result),
	update: ({ previousData, data, id }) =>
		new SubscriptionDistributionSettingsApi(getApiClient())
			.update(id, {
				UpdateSubscriptionDistributionPermissionsCommand: prepareForPost({
					...previousData,
					...data
				})
			})
			.then(to_ra_data_result)
};

export default subscriptionDistributionSettingsProvider;
