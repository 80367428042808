import IconBarChart from '@mui/icons-material/BarChart';
import { lazy } from 'react';
import { Resource } from 'react-admin';
import { ANALYTICS } from './name';

const AnalyticsPage = lazy(() => import(/* webpackChunkName: "analytics" */ './list.js'));

const getAnalyticsResource = perms => (
	<Resource
		key={ANALYTICS}
		name={ANALYTICS}
		options={{ label: 'Analytics' }}
		list={AnalyticsPage}
		icon={IconBarChart}
	/>
);
export default getAnalyticsResource;
