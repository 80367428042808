import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { memo, useCallback } from 'react';
import { Button } from 'react-admin';
import { useNavigate } from 'react-router-dom';

export const BackButton = memo(({ onClick, icon, ...props }) => {
	const navigate = useNavigate();
	const handleClick = useCallback(
		e => {
			onClick?.(e);
			if (!e.defaultPrevented) {
				e.stopPropagation();
				e.preventDefault();
				navigate(-1);
			}
		},
		[navigate, onClick]
	);
	return <Button size="medium" startIcon={icon} {...props} onClick={handleClick} />;
});

BackButton.defaultProps = {
	icon: <ArrowBackIcon />,
	label: 'Back'
};
