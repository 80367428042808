import { EntitiesApi } from '@extend/paywall-api-javascript';
import { getApi } from '../../api/client';
import { to_ra_data_result } from '../../utils/result-converters';

const entityAttachmentsProvider = {
	getMany: async ({ ids, meta: { entity_id } }) => {
		const api = getApi(EntitiesApi);
		const data = await api.getAttachments(entity_id, { identifiers: ids });
		return to_ra_data_result(data);
	},
	getOne: async ({ id, meta: { entity_id } }) => {
		const api = getApi(EntitiesApi);
		const data = await api.getAttachment(entity_id, id);
		return to_ra_data_result(data);
	}
};

export default entityAttachmentsProvider;
