import { TagsApi } from '@extend/paywall-api-javascript';
import { getApiClient } from '../../api/client';
import {
	from_ra_query,
	prepareForPost,
	throwErrors,
	to_ra_data_result,
	to_ra_query_result
} from '../../utils/result-converters';

const tagsProvider = {
	getList: async params => {
		let { filter } = params;
		let { q, withCreate = false, ...rest } = filter;
		params.filter = { q, ...rest };

		var result = to_ra_query_result(await new TagsApi(getApiClient()).query({ TagsQuery: from_ra_query(params) }));

		if (withCreate && q) {
			var existing = result.data.find(d => d.name.toLowerCase() === q.toLowerCase());
			if (!existing) result.data = [{ id: -1000, name: `Create tag ${q}`, newItem: q }, ...result.data];
		}
		return result;
	},
	getMany: ({ ids }) => new TagsApi(getApiClient()).getMany({ request_body: ids }).then(to_ra_data_result),
	getOne: ({ id }) => new TagsApi(getApiClient()).get(id).then(to_ra_data_result),
	create: ({ data }) =>
		new TagsApi(getApiClient())
			.create({ CommandAddTag: prepareForPost(data) })
			.then(to_ra_data_result)
			.catch(throwErrors),
	update: ({ previousData, data, id }) =>
		new TagsApi(getApiClient())
			.update(id, { CommandRenameTag: prepareForPost({ ...previousData, ...data }) })
			.then(to_ra_data_result)
			.catch(throwErrors),
	delete: ({ id }) => new TagsApi(getApiClient()).remove(id).then(to_ra_data_result).catch(throwErrors),
	deleteMany: ({ ids }) => new TagsApi(getApiClient()).removeMany(ids).then(to_ra_data_result).catch(throwErrors)
};

export default tagsProvider;
