import { DISTRIBUTION_PLANS } from '@extend/paywall-api/lib/resource-types';
import { SvgIcon } from '@mui/material';
import { lazy } from 'react';
import { Resource } from 'react-admin';

const DistributionPlansIndex = lazy(() => import(/* webpackChunkName: "distplan-list" */ './list.js'));
const DistributionPlanCreator = lazy(() => import(/* webpackChunkName: "distplan-creator" */ './creator.js'));
const DistributionPlanEditor = lazy(() => import(/* webpackChunkName: "distplan-editor" */ './editor.js'));

const getDistPlansResource = perms => (
	<Resource
		key={DISTRIBUTION_PLANS}
		name={DISTRIBUTION_PLANS}
		options={{ label: 'Study types' }}
		list={DistributionPlansIndex}
		edit={DistributionPlanEditor}
		create={DistributionPlanCreator}
		icon={IconDistributionPLans}
		recordRepresentation="name"
	/>
);

export default getDistPlansResource;

const IconDistributionPLans = props => (
	<SvgIcon {...props} viewBox="0 0 24 24">
		<path d="M22 17c0 2.76-2.24 5-5 5s-5-2.24-5-5 2.24-5 5-5 5 2.24 5 5zM6.5 6.5h3.8L7 1 1 11h5.5V6.5zm9.5 4.085V8H8v8h2.585c.433-2.783 2.632-4.982 5.415-5.415z" />
	</SvgIcon>
);
