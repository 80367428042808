import ErrorIcon from '@mui/icons-material/Error';
import { get } from 'lodash';
import { LinearProgress, useRecordContext } from 'react-admin';
import { useGetAuthImageUrl } from '../hooks/use-get-auth-image-url';
import { renderSingleChild } from '../utils/render';

export const AuthImageField = ({ record: inRecord, source = 'file_url', options, children, allowEmpty, ...props }) => {
	const record = useRecordContext({ record: inRecord });
	const urlValue = get(record || {}, source || 'file_url');
	const { data, isLoading, isSuccess, isError, isIdle, isFetching, isRefetching } = useGetAuthImageUrl(
		urlValue,
		options
	);
	const url = get(data || {}, 'url', null);
	if (record && isSuccess && url) return renderSingleChild(children, { ...props, url: url });
	if (isLoading || isFetching || isRefetching) return <LinearProgress />;
	if (isIdle) return null;
	if (isError) return <ErrorIcon />;
	return <ErrorIcon />;
};
