import { CATEGORIES } from '@extend/paywall-api/lib/resource-types';
import { SvgIcon } from '@mui/material';
import { lazy } from 'react';
import { Resource } from 'react-admin';

const CategoriesIndex = lazy(() => import(/* webpackChunkName: "categories-list" */ './list.js'));
const CategoryCreator = lazy(() => import(/* webpackChunkName: "categories-creator" */ './creator.js'));
const CategoryEditor = lazy(() => import(/* webpackChunkName: "categories-editor" */ './editor.js'));

const getCategoriesResource = perms => (
	<Resource
		key={CATEGORIES}
		name={CATEGORIES}
		options={{ label: 'Categories' }}
		list={CategoriesIndex}
		edit={CategoryEditor}
		create={CategoryCreator}
		icon={IconCategories}
	/>
);

const IconCategories = props => (
	<SvgIcon {...props} viewBox="0 0 32 32">
		<rect x="14" y="25" width="14" height="2" />
		<polygon points="7.17 26 4.59 28.58 6 30 10 26 6 22 4.58 23.41 7.17 26" />
		<rect x="14" y="15" width="14" height="2" />
		<polygon points="7.17 16 4.59 18.58 6 20 10 16 6 12 4.58 13.41 7.17 16" />
		<rect x="14" y="5" width="14" height="2" />
		<polygon points="7.17 6 4.59 8.58 6 10 10 6 6 2 4.58 3.41 7.17 6" />
	</SvgIcon>
);

export default getCategoriesResource;
