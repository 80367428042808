import { DevTool } from '@hookform/devtools';
import { styled } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { Form } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useFormOnValueChange } from './useFormOnValueChange';

export const WdForm = styled(
	({ children, onValueChange, className, debug, ...props }) => {
		return (
			<Form noValidate {...props} className={clsx(className, 'WdForm-root')}>
				<WdDefaultFormBehavior onValueChange={onValueChange} />
				{children}
				{debug && process.env.NODE_ENV !== 'production' ? <Debug /> : null}
			</Form>
		);
	},
	{
		name: 'WdForm',
		slot: 'Root',
		overridesResolver: (props, styles) => styles.root
	}
)({
	'& .MuiFormControl-root': { marginTop: 0 }
});

WdForm.propTypes = {
	...Form.propTypes,
	onValueChange: PropTypes.oneOfType([PropTypes.func, PropTypes.objectOf(PropTypes.func)])
};
WdForm.defaultProps = {
	mode: 'onBlur'
};

/** onValueChange should be memoized */
const WdDefaultFormBehavior = ({ onValueChange }) => {
	useFormOnValueChange(onValueChange);
	return null;
};
const Debug = memo(() => {
	const { control } = useFormContext();
	return <DevTool control={control} placement="bottom-right" />;
});
