import { CONTACTS } from '@extend/paywall-api/lib/resource-types';
import { useMemo } from 'react';
import { useRecordContext } from 'react-admin';
import { WisdropModalUpsert } from '../../components/wisdrop-modal-upsert';
import { customerDisplayName } from '../customers/utils/display-name';
import ContactsModalForm from './form';

const ContactsModalUpsert = ({ id, onClose, ...props }) => {
	const customer = useRecordContext();
	const title = useMemo(
		() =>
			customer
				? id
					? `Editing contact of ${customerDisplayName(customer)}`
					: `New contact for ${customerDisplayName(customer)}`
				: null,
		[customer, id]
	);
	return customer ? (
		<WisdropModalUpsert resource={CONTACTS} title={title} id={id} onClose={onClose} {...props}>
			<ContactsModalForm customer={customer} onClose={onClose} />
		</WisdropModalUpsert>
	) : null;
};

export default ContactsModalUpsert;
