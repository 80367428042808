import { TouApi } from '@extend/paywall-api-javascript';
import { getApiClient } from '../../api/client';
import { to_ra_data_result } from '../../utils/result-converters';

const touProvider = {
	getOne: async ({ id }) => {
		var api = new TouApi(getApiClient());
		var tou = await api.getTermsOfUse();
		return to_ra_data_result(tou);
	},
	update: async ({ data: { content } = {}, id }) => {
		var api = new TouApi(getApiClient());
		var tou = await api.setTermsOfUse({ TouModel: { content, id: 'tou' } });
		return to_ra_data_result(tou);
	}
};

export default touProvider;
