import { AddressesApi, CustomersApi } from '@extend/paywall-api-javascript';
import { getApiClient } from '../../api/client';
import {
	from_ra_query,
	prepareForPost,
	throwErrors,
	to_ra_data_result,
	to_ra_query_result
} from '../../utils/result-converters';

const customerAddressesProvider = {
	getList: params =>
		new AddressesApi(getApiClient()).query({ CustomerAddressQuery: from_ra_query(params) }).then(to_ra_query_result),
	getManyReference: ({ target, id, ...params }) =>
		target === 'customer_id'
			? new CustomersApi(getApiClient())
					.queryAddresses(id, { CustomerAddressQuery: from_ra_query(params) })
					.then(to_ra_query_result)
			: null,
	getMany: ({ ids }) => new AddressesApi(getApiClient()).getMany({ request_body: ids }).then(to_ra_data_result),
	getOne: ({ id }) => new AddressesApi(getApiClient()).get(id).then(to_ra_data_result),
	create: ({ data }) =>
		new AddressesApi(getApiClient())
			.create({ CreateCustomerAddressCommand: prepareForPost(data) })
			.then(to_ra_data_result)
			.catch(throwErrors),
	update: ({ previousData, data, id }) =>
		new AddressesApi(getApiClient())
			.update(id, {
				UpdateCustomerAddressCommand: prepareForPost({ ...previousData, ...data })
			})
			.then(to_ra_data_result)
			.catch(throwErrors),
	delete: ({ id }) => new AddressesApi(getApiClient()).remove(id).then(to_ra_data_result).catch(throwErrors),
	deleteMany: ({ ids }) => new AddressesApi(getApiClient()).removeMany(ids).then(to_ra_data_result).catch(throwErrors)
};

export default customerAddressesProvider;
