import { BRANDS } from '@extend/paywall-api/lib/resource-types';
import IconLabel from '@mui/icons-material/Label';
import { lazy } from 'react';
import { Resource } from 'react-admin';

const BrandsIndex = lazy(() => import(/* webpackChunkName: "brands-list" */ './list.js'));
const BrandCreator = lazy(() => import(/* webpackChunkName: "brands-creator" */ './creator.js'));
const BrandEditor = lazy(() => import(/* webpackChunkName: "brands-editor" */ './editor.js'));

const getBrandsResource = perms => (
	<Resource
		key={BRANDS}
		name={BRANDS}
		options={{ label: 'Brands' }}
		list={BrandsIndex}
		edit={BrandEditor}
		create={BrandCreator}
		icon={IconLabel}
	/>
);
export default getBrandsResource;
