import { TextField } from '@mui/material';
import { useMemo } from 'react';
import { FieldTitle, InputHelperText, useInput, useResourceContext } from 'react-admin';
import { MuiDatePicker } from './date-picker';

export const MuiDatePickerInput = ({
	helperText,
	PopperProps,
	value: _discard,
	alwaysOn,
	allowEmpty,
	clearable,
	label,
	source,
	textInputProps,
	hideHelperText,
	...props
}) => {
	const resource = useResourceContext(props);
	const {
		field: { onBlur, onChange, ref, value },
		fieldState: { isTouched, error },
		formState: { isSubmitted },
		isRequired
	} = useInput({ source, ...props });

	const popperProps = useMemo(() => ({ ...PopperProps, onBlur }), [PopperProps, onBlur]);

	return (
		<MuiDatePicker
			{...props}
			value={value}
			onChange={onChange}
			inputRef={ref}
			PopperProps={popperProps}
			renderInput={params => (
				<TextField
					{...textInputProps}
					{...params}
					InputProps={{ ...params.InputProps, onBlur }}
					label={
						label !== '' &&
						label !== false && (
							<FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
						)
					}
					error={(isTouched || isSubmitted) && error ? error : undefined}
					helperText={
						hideHelperText ? null : (
							<InputHelperText
								touched={isTouched || isSubmitted}
								error={error?.message}
								helperText={helperText}
							/>
						)
					}
				/>
			)}
		/>
	);
};
MuiDatePickerInput.propTypes = {
	...MuiDatePicker.propTypes
};
MuiDatePickerInput.defaultProps = {
	inputFormat: 'dd/MM/yyyy',
	parseFormat: 'yyyy-MM-dd'
};
