import { Suspense } from 'react';
import { Admin, Loading } from 'react-admin';
import { BrowserRouter } from 'react-router-dom';
import { WisdropLayout } from './app-layout';
import { WisdropLocalizationProvider } from './app-localization-provider';
import { WdLogin } from './app-login';
import store from './app-store';
import { wisdropAdminTheme } from './app-theme';
import './App.css';
import authProvider from './auth/provider';
import { queryClient } from './app-query-client';
import { dataProvider, getAdminContent } from './resources';

const App = () => {
	return (
		<BrowserRouter basename={process.env.PUBLIC_URL}>
			<Suspense fallback={<Loading />}>
				<WisdropLocalizationProvider>
					<Admin
						layout={WisdropLayout}
						title="Wisdrop Admin"
						dataProvider={dataProvider}
						authProvider={authProvider}
						loginPage={<WdLogin />}
						queryClient={queryClient}
						store={store}
						theme={wisdropAdminTheme}>
						{getAdminContent}
					</Admin>
				</WisdropLocalizationProvider>
			</Suspense>
		</BrowserRouter>
	);
};

export default App;
