import isUrl from 'is-url';

export const validateForm = ({ industry_id, company_id, campaign, video_link, release_date, length, country_id }) => {
	var errors = {};

	if (!industry_id) errors.industry_id = 'Required';

	if (!company_id) errors.company_id = 'Required';

	if (!campaign) errors.campaign = 'Required';

	if (!video_link) errors.video_link = 'Required';
	else if (!isUrl(video_link)) errors.video_link = 'Value must be a url';

	if (!release_date) errors.release_date = 'Required';

	if (!(length > 0)) errors.length = 'Required';

	if (!country_id) errors.country_id = 'Required';

	return errors;
};
