import { Card } from '@mui/material';
import { CreateBase, Title } from 'react-admin';
import { CustomerForm } from './editor/form';

export const CustomerCreator = () => (
	<Card sx={{ marginTop: 2 }}>
		<Title title="Add new Client" />
		<CreateBase redirect="edit" mutationMode="pessimistic">
			<CustomerForm previewImages />
		</CreateBase>
	</Card>
);
export default CustomerCreator;
