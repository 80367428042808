import { memo, useMemo } from 'react';
import { useListContext } from 'react-admin';
import { DownloadButton } from './download-button';

export const ListExportExcelButton = memo(({ url, fileName, mimeType, label, filter, ...props }) => {
	const { filterValues, sort } = useListContext();
	const parameters = useMemo(
		() => ({
			filter: { ...filterValues, ...(filter || {}) },
			sorting: sort ? [{ field: sort.field, desc: sort.order === 'DESC' }] : null
		}),
		[filterValues, filter, sort]
	);

	return (
		<DownloadButton
			sx={{ whiteSpace: 'nowrap', ...(props.sx || {}) }}
			{...props}
			url={url}
			mimeType={mimeType}
			parameters={parameters}
			label={label ? label : 'Export Data'}
		/>
	);
});
