import { alpha, Stack, styled, Tooltip } from '@mui/material';
import { useMemo } from 'react';
import { DeleteWithConfirmButton, EditButton as RaEditButton, useRecordContext } from 'react-admin';

export const CustomActionColumn = ({
	showEdit,
	showDelete,
	deleteProps,
	showLabels = false,
	editProps,
	record,
	children,
	...props
}) => {
	return (
		<Stack direction="row" flexWrap="nowrap" spacing={1} justifyContent="flex-end">
			{children}
			{showEdit ? (
				<Tooltip title="Edit" placement="top">
					<div>
						<RaEditButton
							sx={{ 'padding': 0, 'minWidth': 0, '& .MuiButton-startIcon': { margin: 0 } }}
							color="edit"
							label={showLabels ? undefined : null}
							size="small"
							{...editProps}
						/>
					</div>
				</Tooltip>
			) : null}
			{showDelete ? (
				<Tooltip title="Delete" placement="top">
					<div>
						<DeleteButton
							sx={{ 'padding': 0, 'minWidth': 0, '& .MuiButton-startIcon': { margin: 0 } }}
							label={showLabels ? undefined : null}
							mutationMode="pessimistic"
							size="small"
							record={record}
							{...deleteProps}
						/>
					</div>
				</Tooltip>
			) : null}
		</Stack>
	);
};

const DeleteButton = styled(
	({ record: inRecord, confirmTitle: inConfirmTitle, confirmContent: inConfirmContent, ...props }) => {
		const record = useRecordContext({ record: inRecord });

		const confirmTitle = useMemo(
			() => (record ? (typeof inConfirmTitle === 'function' ? inConfirmTitle(record) : inConfirmTitle) : undefined),
			[inConfirmTitle, record]
		);

		const confirmContent = useMemo(
			() =>
				record ? (typeof inConfirmContent === 'function' ? inConfirmContent(record) : inConfirmContent) : undefined,
			[inConfirmContent, record]
		);

		return <DeleteWithConfirmButton confirmTitle={confirmTitle} confirmContent={confirmContent} {...props} />;
	}
)(({ theme }) => ({
	'color': theme.palette.delete.main,
	'&:hover': {
		backgroundColor: alpha(theme.palette.delete.main, 0.12)
	}
}));
