import { MediaApi, MediaSlidesApi } from '@extend/paywall-api-javascript';
import { getApi, getApiClient } from '../../api/client';
import { from_ra_query, prepareForPost, to_ra_data_result, to_ra_query_result } from '../../utils/result-converters';

const mediaSlidesProvider = {
	getList: params =>
		new MediaSlidesApi(getApiClient()).query({ MediaSlideQuery: from_ra_query(params) }).then(to_ra_query_result),

	getManyReference: params => {
		if (params.target === 'media_id')
			return new MediaApi(getApiClient())
				.querySlides(params.id, { MediaSlideQuery: from_ra_query(params) })
				.then(to_ra_query_result);
		return null;
	},

	getMany: params => new MediaSlidesApi(getApiClient()).getMany({ request_body: params.ids }).then(to_ra_data_result),
	getOne: params => new MediaSlidesApi(getApiClient()).get(params.id).then(to_ra_data_result),

	create: params =>
		getApi(MediaSlidesApi)
			.create({
				CreateMediaSlideCommand: prepareForPost(params.data)
			})
			.then(to_ra_data_result),

	update: params =>
		getApi(MediaSlidesApi)
			.update(params.id, {
				UpdateMediaSlideCommand: prepareForPost({ ...params.previousData, ...params.data })
			})
			.then(to_ra_data_result),

	delete: params => new MediaSlidesApi(getApiClient()).remove(params.id).then(to_ra_data_result),
	deleteMany: params =>
		new MediaSlidesApi(getApiClient()).removeMany({ request_body: params.ids }).then(to_ra_data_result)
};

export default mediaSlidesProvider;
