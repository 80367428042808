import { ApiClient, StatsApi } from '@extend/paywall-api-javascript';
import { parse as parseDisposition } from '@tinyhttp/content-disposition';
import get from 'lodash/get';
import { useCallback } from 'react';
import { useMutation } from 'react-query';
import authProvider from '../auth/provider';
import { getError } from '../utils/errors';

let basePath = null;
export const setApiBasePath = path => {
	basePath = path;
};

export const getApiBasePath = pathOverride =>
	pathOverride || basePath || window.apiBasePath || process.env.REACT_APP_API_URL;

export const callApi = async ({ apiBasePath, endpoint, asBlob, ...options }) => {
	let opts = options || {};

	var apiKey = authProvider.getAuthToken();
	if (apiKey)
		opts.headers = {
			...opts.headers,
			Authorization: `Bearer ${apiKey}`
		};

	if (asBlob)
		opts.headers = {
			...opts.headers,
			Accept: opts.headers?.Accept || 'application/octet-stream'
		};

	let bPath = getApiBasePath(apiBasePath);

	try {
		let response = await fetch(`${bPath}${endpoint}`, opts);

		if (response.ok) {
			if (asBlob) {
				var blob = await response.blob();
				return blob;
			} else return response.json();
		}

		let { status, statusText } = response;
		let message = statusText;
		try {
			let responseText = await response.text();
			message = `${message}: ${responseText}`;
		} catch {}

		return Promise.reject(getError(status || 500, message));
	} catch (e) {
		Promise.reject(
			getError(
				e.status || 500,
				get(e, 'message') ||
					get(e, 'errorMessage') ||
					get(e, 'error.message') ||
					get(e, 'error.response.message') ||
					get(e, 'error.response.body.message') ||
					get(e, 'errorText') ||
					get(e, 'responseText', 'Unspecified error')
			)
		);
	}
};
export const callApiDownload = async ({ apiBasePath, endpoint, asBlob, ...options }) => {
	let opts = options || {};

	var apiKey = authProvider.getAuthToken();
	if (apiKey)
		opts.headers = {
			...opts.headers,
			Authorization: `Bearer ${apiKey}`
		};

	opts.headers = {
		...opts.headers,
		Accept: opts.headers?.Accept || 'application/octet-stream'
	};

	let bPath = getApiBasePath(apiBasePath);

	try {
		let response = await fetch(`${bPath}${endpoint}`, opts);

		if (response.ok) {
			var blob = await response.blob();
			const respData = {};
			try {
				response.headers.forEach((value, key) => {
					if (key.localeCompare('content-disposition', undefined, { sensitivity: 'base' }) === 0) {
						const { type, parameters: { filename } = {} } = parseDisposition(value);
						respData.fileName = decodeURIComponent(filename)?.replace(/(^')|('$)/g, '');
						respData.fileType = type;
					}
				});
			} catch (e) {
				console.error('Error parsing content-disposition header', e);
			}

			return { blob, ...respData };
		}

		let { status, statusText } = response;
		let message = statusText;
		try {
			let responseText = await response.text();
			message = `${message}: ${responseText}`;
		} catch {}

		return Promise.reject(getError(status || 500, message));
	} catch (e) {
		Promise.reject(
			getError(
				e.status || 500,
				get(e, 'message') ||
					get(e, 'errorMessage') ||
					get(e, 'error.message') ||
					get(e, 'error.response.message') ||
					get(e, 'error.response.body.message') ||
					get(e, 'errorText') ||
					get(e, 'responseText', 'Unspecified error')
			)
		);
	}
};

export const uploadFiles = ({ apiBasePath, endpoint, files, fileProp, headers, method, ...options }) => {
	if (!files) return Promise.reject();

	fileProp = fileProp || 'files';
	let formData = new FormData();
	if (files.length) {
		for (var i = 0; i < files.length; ++i) formData.append(fileProp, files[i], files[i].name);
	} else formData.append(fileProp, files, files.name);

	let { 'Content-Type': ct, ...restHeaders } = { ...headers };

	return callApi({
		apiBasePath,
		endpoint,
		...options,
		headers: restHeaders,
		method: method || 'POST',
		body: formData
	});
};

export const nativeDownloadFiles = ({ apiBasePath, endpoint, method, asBlob, ...options }) =>
	callApiDownload({ apiBasePath, endpoint, method: method || 'GET', ...options });

export const getApiClient = customBasePath => {
	let api = new ApiClient();

	api.defaultHeaders = {};
	api.basePath = getApiBasePath(customBasePath);
	let bearer = api.authentications['Bearer'];
	bearer.apiKey = authProvider.getAuthToken();
	bearer.apiKeyPrefix = 'Bearer';
	return api;
};

export const getApi = (ApiClass, customBasePath) => new ApiClass(getApiClient(customBasePath));

export const useLogAction = (action, opts) => {
	const mutateFn = useCallback(
		async data => {
			var api = new StatsApi(getApiClient());
			var result = await api.logUserAction({ LogUserActionModel: { action: action, ...data } });
			return !!result.success;
		},
		[action]
	);

	const handleError = useCallback((err, variables, context) => {
		console.error('Log User Action error', { error: err, variables, context });
	}, []);

	return useMutation(mutateFn, {
		mutationKey: ['logAction', action],
		retry: 10,
		retryDelay: attempt => attempt * 200,
		onError: handleError,
		...opts
	});
};

export const uploadFile = async (rawFile, endpoint) =>
	(
		await uploadFiles({
			endpoint: endpoint || '/api/v1/File',
			method: 'POST',
			fileProp: 'files',
			files: [rawFile]
		})
	)[0];
export const uploadMultipleFiles = async (rawFiles, endpoint) =>
	await uploadFiles({
		endpoint: endpoint || '/api/v1/File',
		method: 'POST',
		fileProp: 'files',
		files: rawFiles
	});
