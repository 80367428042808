import { createContext, useContext } from 'react';
import { ContactsModalUpsert } from '.';
import { useModalUpsert } from '../../hooks/use-modal-upsert';

export const ContactEditorContext = createContext({
	open: false,
	openEditor: () => {},
	openCreator: () => {},
	onClose: () => {},
	id: null
});

export const useContactEditor = () => useContext(ContactEditorContext);
export const ContactEditorProvider = ({ children }) => {
	const context = useModalUpsert();
	return (
		<ContactEditorContext.Provider value={context}>
			{children}
			<ContactsModalUpsert open={context?.open} onClose={context?.onClose} id={context?.id} />
		</ContactEditorContext.Provider>
	);
};
