/* eslint-disable no-control-regex */

const emailRegex =
	/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
const domainNameRegex =
	/^(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;

const validateArrayItems = function (array, tester) {
	var errs = array.reduce((vals, current) => {
		if (current && !tester(current)) vals.push(current);
		return vals;
	}, []);

	if (errs.length === 0) return;

	return errs;
};

export const ERROR_INVALID_TYPE = 1;
export const ERROR_INVALID_VALUE = 2;

const defaultCsvSeparator = /[\r\n\s|;:,]+/g;

const createCsvValidator = tester => value => {
	if (value === null || value === undefined || value === '') return;
	if (typeof value !== 'string') return { errorType: ERROR_INVALID_TYPE, payload: { type: typeof value, value } };
	var array = defaultCsvSeparator[Symbol.split](value);
	var res = validateArrayItems(array, tester);
	if (Array.isArray(res)) return { errorType: ERROR_INVALID_VALUE, payload: res };
};

const createArrayValidator = tester => value => {
	if (value === null || value === undefined) return;
	if (!Array.isArray(value)) return { errorType: ERROR_INVALID_TYPE, payload: { type: typeof value, value } };
	if (value.length === 0) return;
	var res = validateArrayItems(value, tester);
	if (Array.isArray(res)) return { errorType: ERROR_INVALID_VALUE, payload: res };
};

export const validateCsvEmails = createCsvValidator(value => emailRegex.test(value));
export const validateArrayEmails = createArrayValidator(value => emailRegex.test(value));
export const validateCsvDomainNames = createCsvValidator(value => domainNameRegex.test(value));
export const validateArrayDomainNames = createArrayValidator(value => domainNameRegex.test(value));
