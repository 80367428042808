import { SUBSCRIPTION_TYPES } from '@extend/paywall-api/lib/resource-types';
import { DialogContent, Stack } from '@mui/material';
import dfnsFormat from 'date-fns/format';
import { useMemo } from 'react';
import {
	AutocompleteInput,
	BooleanInput,
	LinearProgress,
	NumberInput,
	ReferenceInput,
	SelectInput,
	TextInput,
	useDataProvider
} from 'react-admin';
import { useDateAdapter } from '../../app-localization-provider';
import { MuiDatePickerInput } from '../../components/date-picker-input';
import { WdForm } from '../../components/form';
import { FormToolbar } from '../../components/form/toolbar';
import { paymentModeChoices, PER_MONTH } from './payment-modes';
import validate from './validation';

const today = dfnsFormat(new Date(), 'yyyy-MM-dd');

const SubscriptionsModalForm = ({ customer, onClose }) => {
	const cancelProps = useMemo(
		() => ({
			onClick: onClose
				? e => {
						e.preventDefault();
						onClose();
				  }
				: undefined
		}),
		[onClose]
	);

	const defaultValues = useMemo(
		() => ({
			customer_id: customer?.id,
			payment_mode: PER_MONTH,
			valid_from: today,
			purchased_date: today,
			paid_amount: 0
		}),
		[customer?.id]
	);

	const onValueChange = useSubscriptionFormChangeHandlers();

	return customer ? (
		<WdForm defaultValues={defaultValues} validate={validate} onValueChange={onValueChange}>
			<DialogContent>
				<Stack spacing={0}>
					<SelectInput source="payment_mode" optionText="text" choices={paymentModeChoices} />
					<ReferenceInput
						perPage={1000}
						source="subscription_type_id"
						reference={SUBSCRIPTION_TYPES}
						sort={{ field: 'sorting_order' }}>
						<AutocompleteInput optionText="title" label="Subscription Plan" />
					</ReferenceInput>
					<MuiDatePickerInput source="valid_from" label="Valid From" />
					<MuiDatePickerInput source="valid_to" label="Valid To" />
					<MuiDatePickerInput source="purchased_date" label="Purchased Date" clearable />
					<BooleanInput source="is_paid" label="Is Paid" />
					<NumberInput source="paid_amount" step={1} label="Paid amount" />
					<TextInput source="description" label="Description" multiline rows={2} />
				</Stack>
			</DialogContent>
			<FormToolbar cancel cancelOpts={cancelProps} />
		</WdForm>
	) : (
		<LinearProgress />
	);
};

export default SubscriptionsModalForm;

const useSubscriptionFormChangeHandlers = () => {
	const dataProvider = useDataProvider();
	const adapter = useDateAdapter();
	const onValueChange = useMemo(
		() => ({
			subscription_type_id: async ({ setValue, data: { subscription_type_id, valid_from } }) => {
				if (subscription_type_id) {
					const {
						data: { duration }
					} = await dataProvider.getOne(SUBSCRIPTION_TYPES, { id: subscription_type_id });
					const valid_to = adapter.formatByString(
						adapter.addDays(adapter.addMonths(adapter.date(valid_from), duration), -1),
						'yyyy-MM-dd'
					);
					setValue('valid_to', valid_to);
				}
			}
		}),
		[adapter, dataProvider]
	);
	return onValueChange;
};
