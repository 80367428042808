export const datify = input_date => {
	if (!input_date || input_date === '') {
		return null;
	}
	const date = input_date instanceof Date ? input_date : new Date(input_date);
	if (isNaN(date)) {
		throw new Error(`Invalid date: ${input_date}`);
	}
	return date;
};
