import { SUBSCRIPTIONS } from '@extend/paywall-api/lib/resource-types';
import { useMemo } from 'react';
import { useRecordContext } from 'react-admin';
import { WisdropModalUpsert } from '../../components/wisdrop-modal-upsert';
import { customerDisplayName } from '../customers/utils/display-name';
import SubscriptionsModalForm from './form';

const SubscriptionsModalUpsert = ({ id, onClose, ...props }) => {
	const customer = useRecordContext();
	const title = useMemo(
		() =>
			id
				? `Editing subscription of ${customerDisplayName(customer)}`
				: `New subscription for ${customerDisplayName(customer)}`,
		[customer, id]
	);
	return (
		<WisdropModalUpsert resource={SUBSCRIPTIONS} title={title} id={id} onClose={onClose} {...props}>
			<SubscriptionsModalForm customer={customer} onClose={onClose} />
		</WisdropModalUpsert>
	);
};

export default SubscriptionsModalUpsert;
