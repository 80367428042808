import { COUNTRIES, CUSTOMERS } from '@extend/paywall-api/lib/resource-types';
import { AutocompleteInput, ReferenceInput } from 'react-admin';
import { MuiDatePickerInput } from '../../components/date-picker-input';
import { customerDisplayName } from '../customers/utils/display-name';

export const analyticsFilter = [
	<ReferenceInput
		perPage={1000}
		reference={COUNTRIES}
		filter={{ with_content: true }}
		sort={{ field: 'name' }}
		source="countryId"
		allowEmpty
		alwaysOn>
		<AutocompleteInput label="Country" optionText="name" sx={{ minWidth: '200px' }} />
	</ReferenceInput>,
	<ReferenceInput
		perPage={1000}
		source="customerId"
		reference={CUSTOMERS}
		sort={{ field: 'last_name' }}
		allowEmpty
		alwaysOn>
		<AutocompleteInput label="Client" optionText={customerDisplayName} sx={{ minWidth: '250px' }} />
	</ReferenceInput>,
	<MuiDatePickerInput source="from" label="Since" alwaysOn InputProps={{ sx: { maxWidth: '180px' } }} />,
	<MuiDatePickerInput source="to" label="Until" alwaysOn InputProps={{ sx: { maxWidth: '180px' } }} />
];

/* <ReferenceInput
	perPage={1000}
	
	source="customerId"
	reference={CUSTOMERS}
	sort={{ field: 'last_name' }}
	allowEmpty
	alwaysOn>
	<AutocompleteInput label="Client" optionText={c => `${c.last_name} ${c.name}`} />
</ReferenceInput>, */
