import { get } from 'lodash';

const _tz_offset = new Date().getTimezoneOffset();

export const prepareForPost = data => {
	if (data === null) return null;
	if (data instanceof Date) return new Date(data.getTime() - _tz_offset * 60000);
	if (Array.isArray(data)) return data.map(i => prepareForPost(i));
	if (typeof data === 'object') {
		var result = {};
		for (var prop in data) result[prop] = prepareForPost(data[prop]);
		return result;
	}
	return data;
};

export const from_ra_query = ({ pagination, sort, filter, target, id }) => {
	let query = {};

	var actualFilter = {};
	var hasFilter = false;

	if (filter) {
		var filterProps = Object.keys(filter);
		hasFilter = filterProps.length > 0;
		filterProps.forEach(key => (actualFilter[key] = filter[key]));
	}

	if (target) {
		actualFilter[target] = id;
		hasFilter = true;
	}

	if (hasFilter) query.filter = prepareForPost(actualFilter);

	if (pagination) {
		let { page, perPage } = { page: 0, perPage: 0, ...pagination };
		if (perPage > 0) {
			query.skip = (page - 1) * perPage;
			query.take = perPage;
		}
	}

	if (sort) {
		let { field, order } = sort;
		query.sorting = [{ field: field, desc: order === 'DESC' }];
	}
	return query;
};

export const to_ra_query_result = result => {
	if (Array.isArray(validateResultExists(result)))
		return {
			data: result,
			total: result.length
		};
	return {
		data: result.items,
		total: result.total
	};
};
export const map_field_to_id = field => result => {
	let res = null;
	if (Array.isArray(result)) res = result.map(r => ({ ...r, id: get(r, field) }));
	else if (!!result) res = { ...result, id: get(result, field) };
	return res;
};

export const to_ra_data_result = result => {
	return { data: validateResultExists(result) };
};

export const to_ra_data_dictionary_result = (ids, result) => {
	const _res = { data: [] };
	if (Array.isArray(result)) {
		_res.data = result;
	} else if (typeof result === 'object') {
		_res.data = ids.map(id => result[id] ?? { id: id });
	}
	return _res;
};

export const to_ra_data_item_result = result => {
	if (Array.isArray(validateResultExists(result))) {
		if (result.length > 0) {
			return { data: result[0] };
		} else {
			throwNotFound();
			return { data: null };
		}
	} else {
		return { data: result };
	}
};

export const validateResultExists = result => {
	if (result == null) throwNotFound();
	return result;
};

export function throwNotFound() {
	const error = new Error('no content');
	error.status = 204;
	throw error;
}

export const throwErrors = responseOrError => {
	if (responseOrError instanceof Error) return Promise.reject(responseOrError);
	const errs = {};
	if (responseOrError?.status === 404) {
		const errorObj = new Error(`Not found`);
		errorObj.errors = errs;
		errorObj.status = responseOrError.status;
		return Promise.reject(errorObj);
	}
	if (responseOrError?.body?.errors)
		for (const prop in responseOrError.body.errors) {
			if (responseOrError.body.errors[prop] !== undefined)
				errs[prop] = Array.isArray(responseOrError.body.errors[prop])
					? responseOrError.body.errors[prop][0]
					: responseOrError.body.errors[prop];
		}
	var _error = responseOrError?.body?.error ?? responseOrError?.error ?? 'Unidentified error';
	const errorObj = new Error(`${_error}`);
	errorObj.errors = errs;
	errorObj.status = responseOrError.status;
	return Promise.reject(errorObj);
};
