import { LearnMoreApi } from '@extend/paywall-api-javascript';
import { getApiClient } from '../../api/client';
import { from_ra_query, throwErrors, to_ra_data_result, to_ra_query_result } from '../../utils/result-converters';

const learnMoreProvider = {
	getList: params =>
		new LearnMoreApi(getApiClient())
			.query({ ExpressionOfInterestQuery: from_ra_query(params) })
			.then(to_ra_query_result),
	getMany: ({ ids }) => new LearnMoreApi(getApiClient()).getMany({ request_body: ids }).then(to_ra_data_result),
	getOne: ({ id }) => new LearnMoreApi(getApiClient()).get(id).then(to_ra_data_result),
	delete: ({ id }) => new LearnMoreApi(getApiClient()).remove(id).then(to_ra_data_result).catch(throwErrors),
	deleteMany: ({ ids }) => new LearnMoreApi(getApiClient()).removeMany(ids).then(to_ra_data_result).catch(throwErrors)
};

export default learnMoreProvider;
