import { appImageDownloader } from '../../app-image-downloader';
import { to_ra_data_result } from '../../utils/result-converters';

const blobsProvider = {
	getOne: async ({ id }) => {
		const url = await appImageDownloader.downloadImage(id);
		return to_ra_data_result({ id, url });
	}
};

export default blobsProvider;
