import { FILE } from '@extend/paywall-api/lib/resource-types';
import { DialogContentText, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { DeleteButton, FileField, NumberField, ReferenceField, TextField, useRecordContext } from 'react-admin';
import { UploadedImageField } from '../../components/fields/uploaded-image-field';
import { useModalUpsertContext } from '../../components/modal-upsert-context';
import { OnlyTrueBooleanField } from '../../components/only-true-boolean-field';
import { WdDataGrid } from '../../components/wisdrop-data-grid';
import { getMediaSmallTitle } from '../media/small-title';
const currencyFieldOptions = { maximumFractionDigits: 2, style: 'currency', currency: 'EUR' };

const MediaSlidesGrid = props => {
	const media = useRecordContext(props);
	const { openEditor } = useModalUpsertContext();

	return (
		<WdDataGrid {...props} rowClick={openEditor} bulkActionButtons={false}>
			<NumberField source="slide_number" label="A/A" />
			<TextField source="title" label="Title" />
			<TextField source="description" label="Description" />
			<OnlyTrueBooleanField source="allow_comments" label="Comments allowed" />
			<NumberField source="price" label="Price" locales="el-GR" options={currencyFieldOptions} />
			<ReferenceField source="content_id" label="Image" reference={FILE} link={false} cellClassName="no-y-padding">
				<UploadedImageField />
			</ReferenceField>
			<ReferenceField source="download_file_id" label="Powerpoint" reference={FILE} link={false}>
				<FileField source="file_url" title="name" />
			</ReferenceField>
			<DeleteMediaSlideButton media={media} />
		</WdDataGrid>
	);
};

const DeleteMediaSlideButton = ({ media }) => {
	const title = useMemo(() => `Deleting slide of report ${getMediaSmallTitle(media)}`, [media]);
	return (
		<DeleteButton
			confirmTitle={title}
			confirmContent={<DeleteMediaSlideContent />}
			redirect={false}
			mutationMode="pessimistic"
		/>
	);
};

const DeleteMediaSlideContent = props => {
	const mediaSlide = useRecordContext(props);
	return (
		<Stack spacing={2}>
			<Typography variant="body1" component="p">
				Are you sure you want to delete this slide?
			</Typography>
			<DialogContentText component="p">{mediaSlide.slide_number}</DialogContentText>
		</Stack>
	);
};

export default MediaSlidesGrid;
