import { DistributionPlansApi } from '@extend/paywall-api-javascript';
import { getApiClient } from '../../api/client';
import {
	from_ra_query,
	prepareForPost,
	throwErrors,
	to_ra_data_result,
	to_ra_query_result
} from '../../utils/result-converters';

const distPlansProvider = {
	getList: params =>
		new DistributionPlansApi(getApiClient())
			.query({ DistributionPlanQuery: from_ra_query(params) })
			.then(to_ra_query_result),
	getMany: ({ ids }) =>
		new DistributionPlansApi(getApiClient()).getMany({ request_body: ids }).then(to_ra_data_result),
	getOne: ({ id }) => new DistributionPlansApi(getApiClient()).get(id).then(to_ra_data_result),
	create: ({ data }) =>
		new DistributionPlansApi(getApiClient())
			.create({ CommandAddDistributionPlan: prepareForPost(data) })
			.then(to_ra_data_result)
			.catch(throwErrors),
	update: ({ previousData, data, id }) =>
		new DistributionPlansApi(getApiClient())
			.update(id, {
				CommandEditDistributionPlan: prepareForPost({ ...previousData, ...data })
			})
			.then(to_ra_data_result)
			.catch(throwErrors)
};

export default distPlansProvider;
