import { Resource } from 'react-admin';
import { TERMS_OF_USE } from './name';
import IconDescription from '@mui/icons-material/DescriptionSharp';
import { lazy } from 'react';

const TermsOfUseEditor = lazy(() => import(/* webpackChunkName: "tou-editor" */ './editor.js'));

const getTermsOfUseResource = perms => (
	<Resource
		key={TERMS_OF_USE}
		name={TERMS_OF_USE}
		options={{ label: 'Terms of use' }}
		list={TermsOfUseEditor}
		icon={IconDescription}
	/>
);
export default getTermsOfUseResource;
