import { EntitiesApi, MediaApi } from '@extend/paywall-api-javascript';
import { getApi, getApiClient } from '../../api/client';
import { from_ra_query, prepareForPost, to_ra_data_result, to_ra_query_result } from '../../utils/result-converters';
import { alphanumericCollator, uploadFilesToBatch } from '../../utils/uploadFilesToBatch';

const mediaProvider = {
	getList: params =>
		new MediaApi(getApiClient()).query({ MediaQuery: from_ra_query(params) }).then(to_ra_query_result),
	getMany: ({ ids }) => new MediaApi(getApiClient()).getMany({ request_body: ids }).then(to_ra_data_result),
	getManyReference: ({ target, id, ...params }) => {
		var actualParams = { ...params, [target]: id };
		return new MediaApi(getApiClient()).query({ MediaQuery: from_ra_query(actualParams) }).then(to_ra_query_result);
	},
	getOne: async ({ id, meta }) => {
		const api = new MediaApi(getApiClient());
		var res = await api.get(id);
		if (meta?.edit) {
			const refs = await api.getOneToManyReferences(id);
			const { available_in_country_ids, tag_ids, category_ids } = refs;
			// get thumbnail
			const eApi = getApi(EntitiesApi);
			const atts = await eApi.getAttachments(id);
			res = {
				...res,
				attachments: (atts || []).reduce((v, att) => {
					v[att.identifier] = att.id;
					return v;
				}, {}),
				category_ids: (category_ids || []).map(i => i.id || i),
				available_in_country_ids: (available_in_country_ids || []).map(i => i.id || i),
				tag_ids: (tag_ids || []).map(i => i.id || i)
			};
		}
		return to_ra_data_result(res);
	},
	create: ({ data }) =>
		new MediaApi(getApiClient()).create({ CreateMediaCommand: prepareForPost(data) }).then(to_ra_data_result),
	update: ({ previousData, data, id }) => {
		delete previousData.attachments;
		return new MediaApi(getApiClient())
			.update(id, { UpdateMediaCommand: prepareForPost({ ...previousData, ...data }) })
			.then(to_ra_data_result);
	},
	delete: ({ id }) => new MediaApi(getApiClient()).remove(id).then(to_ra_data_result),
	deleteMany: ({ ids }) => new MediaApi(getApiClient()).removeMany(ids).then(to_ra_data_result),

	addMediaBatchSlides: async ({ id, files, is_topline, progressReset, progressUpdate }) => {
		var batch_id = await uploadFilesToBatch({ files, progressReset, progressUpdate });

		var slideInfo = files
			.map(f => f.rawFile.name)
			.sort(alphanumericCollator.compare)
			.map(name => ({ name: name }));

		var mediaApi = new MediaApi(getApiClient());
		var result = await mediaApi.addSlidesFromBatch(id, {
			BatchCreateMediaSlideCommand: {
				media_id: id,
				batch_id: batch_id,
				is_topline: !!is_topline,
				slides: slideInfo
			}
		});
		return to_ra_data_result(result);
	},

	addMediaBatchSlideAttachements: async ({ id, files, is_topline, progressReset, progressUpdate }) => {
		var batch_id = await uploadFilesToBatch({ files, progressReset, progressUpdate });

		var mediaApi = new MediaApi(getApiClient());
		var result = await mediaApi.addAttachmentsFromBatch(id, {
			BatchUploadMediaSlideAttachmentsCommand: {
				media_id: id,
				batch_id: batch_id,
				is_topline: !!is_topline
			}
		});
		return to_ra_data_result(result);
	},

	addCustomerAvailability: ({ id, data }) =>
		new MediaApi(getApiClient())
			.addCustomersToAvailability(id, {
				CustomerIdsParams: prepareForPost(data)
			})
			.then(to_ra_data_result),

	removeCustomerAvailability: ({ id, data }) =>
		new MediaApi(getApiClient())
			.removeCustomersFromAvailability(id, {
				CustomerIdsParams: prepareForPost(data)
			})
			.then(to_ra_data_result),

	deleteAllSlides: ({ id, is_topline }) =>
		new MediaApi(getApiClient()).deleteAllSlides(id, is_topline).then(to_ra_data_result)
};

export default mediaProvider;
