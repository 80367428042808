import { IndustriesApi } from '@extend/paywall-api-javascript';
import { getApiClient } from '../../api/client';
import {
	from_ra_query,
	prepareForPost,
	throwErrors,
	to_ra_data_result,
	to_ra_query_result
} from '../../utils/result-converters';

const industriesProvider = {
	getList: async params =>
		new IndustriesApi(getApiClient()).query({ IndustryQuery: from_ra_query(params) }).then(to_ra_query_result),
	getMany: ({ ids }) => new IndustriesApi(getApiClient()).getMany({ request_body: ids }).then(to_ra_data_result),
	getOne: ({ id }) => new IndustriesApi(getApiClient()).get(id).then(to_ra_data_result),
	create: ({ data }) =>
		new IndustriesApi(getApiClient())
			.create({ CreateIndustryCommand: prepareForPost(data) })
			.then(to_ra_data_result)
			.catch(throwErrors),
	update: ({ previousData, data, id }) =>
		new IndustriesApi(getApiClient())
			.update(id, { CreateIndustryCommand: prepareForPost({ ...previousData, ...data }) })
			.then(to_ra_data_result)
			.catch(throwErrors),
	delete: ({ id }) => new IndustriesApi(getApiClient()).remove(id).then(to_ra_data_result).catch(throwErrors),
	deleteMany: ({ ids }) => new IndustriesApi(getApiClient()).removeMany(ids).then(to_ra_data_result).catch(throwErrors)
};

export default industriesProvider;
