import { useEffect } from 'react';
import { useRef } from 'react';

export const useConvertToRef = value => {
	const defValRef = useRef(value);
	useEffect(() => {
		defValRef.current = value;
	}, [value]);
	return defValRef;
};
