import { MediaReadStatsApi } from '@extend/paywall-api-javascript';
import { getApiClient } from '../../api/client';
import { to_ra_data_result } from '../../utils/result-converters';

const mediaReadStatsProvider = {
	getMany: ({ ids }) => new MediaReadStatsApi(getApiClient()).getMany({ request_body: ids }).then(to_ra_data_result),
	getOne: ({ id }) => new MediaReadStatsApi(getApiClient()).get(id).then(to_ra_data_result)
};

export default mediaReadStatsProvider;
