const createBlobCacheInstance = () => {
	var blobMap = {};

	var clearBlob = key => {
		let blobURL = blobMap[key];
		if (blobURL) {
			URL.revokeObjectURL(blobURL);
			delete blobMap[key];
		}
	};

	return {
		getBlob: async (key, blobGenerator) => {
			let blob = blobMap[key];
			if (!blob) {
				try {
					blob = await blobGenerator();
				} catch (e) {
					blob = null;
				}
				if (blob) blobMap[key] = blob;
			}
			return blob || null;
		},
		clearBlob: clearBlob,
		clearAllBlobs: () => {
			try {
				for (var key in blobMap) {
					try {
						clearBlob(key);
					} catch {}
				}
			} catch {}
		}
	};
};

export const BlobCache = createBlobCacheInstance();
