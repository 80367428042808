import { TAGS } from '@extend/paywall-api/lib/resource-types';
import { Resource } from 'react-admin';
import IconLocalOffer from '@mui/icons-material/LocalOffer';
import { lazy } from 'react';

const TagsIndex = lazy(() => import(/* webpackChunkName: "tags-list" */ './list.js'));
const TagCreator = lazy(() => import(/* webpackChunkName: "tags-creator" */ './creator.js'));
const TagEditor = lazy(() => import(/* webpackChunkName: "tags-editor" */ './editor.js'));

const getTagsResource = perms => (
	<Resource
		key={TAGS}
		name={TAGS}
		options={{ label: 'Tags' }}
		list={TagsIndex}
		edit={TagEditor}
		create={TagCreator}
		icon={IconLocalOffer}
	/>
);
export default getTagsResource;
