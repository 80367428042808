import { SUBSCRIPTION_DISTRIBUTION_SETTINGS } from '@extend/paywall-api/lib/resource-types';
import { SvgIcon } from '@mui/material';
import { lazy } from 'react';
import { Resource } from 'react-admin';

const SubscriptionDistributionSettingsIndex = lazy(() => import(/* webpackChunkName: "subsc-dist-list" */ './list.js'));
const SubscriptionDistributionSettingsEditor = lazy(() =>
	import(/* webpackChunkName: "subsc-dist-editor" */ './editor.js')
);

const getSubscriptionDistributionSettingsResource = perms => (
	<Resource
		name={SUBSCRIPTION_DISTRIBUTION_SETTINGS}
		options={{ label: 'Report Permissions' }}
		list={SubscriptionDistributionSettingsIndex}
		edit={SubscriptionDistributionSettingsEditor}
		icon={IconReportPermissions}
	/>
);

const IconReportPermissions = props => (
	<SvgIcon {...props} viewBox="0 0 330 330">
		<path
			d="M65,330h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15h-15V85c0-46.869-38.131-85-85-85
		S80,38.131,80,85v45H65c-8.284,0-15,6.716-15,15v170C50,323.284,56.716,330,65,330z M207.481,219.356l-42.5,42.5
		c-2.929,2.929-6.768,4.394-10.606,4.394s-7.678-1.465-10.606-4.394l-21.25-21.25c-5.858-5.858-5.858-15.354,0-21.213
		c5.857-5.858,15.355-5.858,21.213,0l10.644,10.643l31.894-31.893c5.857-5.858,15.355-5.858,21.213,0
		C213.34,204.002,213.34,213.498,207.481,219.356z M110,85c0-30.327,24.673-55,55-55s55,24.673,55,55v45H110V85z"
		/>
	</SvgIcon>
);

export default getSubscriptionDistributionSettingsResource;
