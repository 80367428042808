import { throwErrors } from '../../utils/result-converters';

export class WisdropDataProvider {
	constructor(providers) {
		const _providers = { ...(providers || {}) };
		this.execute = async (resource, action, params) => {
			try {
				if (!resource) throw new Error(`No resource provided for action ${action}`);

				// calculate dynamic resource
				const [finalResource, ...dynamicParams] = resource.split(':');

				const resourceProvider = _providers[finalResource];
				if (!resourceProvider) throw new Error(`There is no data provider for resource '${finalResource}'`);

				const actionHandler = resourceProvider[action];
				if (!actionHandler || typeof actionHandler !== 'function')
					throw new Error(
						`There is no action handler for action '${action}' for data provider for resource '${finalResource}'`
					);
				if (dynamicParams && dynamicParams.length > 0) params = { ...params, dynamicParams };
				return await Promise.resolve(actionHandler(params));
			} catch (e) {
				return throwErrors(e);
			}
		};
	}

	getList = (resource, params) => this.execute(resource, 'getList', params);
	getOne = (resource, params) => this.execute(resource, 'getOne', params);
	getMany = (resource, params) => this.execute(resource, 'getMany', params);
	getManyReference = (resource, params) => this.execute(resource, 'getManyReference', params);
	create = (resource, params) => this.execute(resource, 'create', params);
	update = (resource, params) => this.execute(resource, 'update', params);
	updateMany = (resource, params) => this.execute(resource, 'updateMany', params);
	delete = (resource, params) => this.execute(resource, 'delete', params);
	deleteMany = (resource, params) => this.execute(resource, 'deleteMany', params);
}

export default WisdropDataProvider;
