import { KeyTakeAwaysApi } from '@extend/paywall-api-javascript';
import { getApiClient } from '../../api/client';
import { from_ra_query, prepareForPost, to_ra_data_result, to_ra_query_result } from '../../utils/result-converters';

const keyTakeAwaysProvider = {
	getList: params =>
		new KeyTakeAwaysApi(getApiClient()).query({ KeyTakeAwayQuery: from_ra_query(params) }).then(to_ra_query_result),
	getMany: ({ ids }) => new KeyTakeAwaysApi(getApiClient()).getMany({ request_body: ids }).then(to_ra_data_result),
	getOne: ({ id }) => new KeyTakeAwaysApi(getApiClient()).get(id).then(to_ra_data_result),
	create: ({ data }) => {
		return new KeyTakeAwaysApi(getApiClient())
			.create({ CreateKeyTakeAwayModel: prepareForPost(data) })
			.then(to_ra_data_result);
	},
	update: ({ previousData, data, id }) =>
		new KeyTakeAwaysApi(getApiClient())
			.update(id, { UpdateKeyTakeAwayModel: prepareForPost({ ...previousData, ...data }) })
			.then(to_ra_data_result),
	delete: ({ id }) => new KeyTakeAwaysApi(getApiClient()).remove(id).then(to_ra_data_result),
	deleteMany: ({ ids }) => new KeyTakeAwaysApi(getApiClient()).removeMany(ids).then(to_ra_data_result),

	getManyReference: ({ id, target, ...params }) => {
		if (target === 'media') {
			return new KeyTakeAwaysApi(getApiClient()).getReferences(target, id).then(to_ra_query_result);
		}
		return null;
	}
};

export const mediaKeyTakeAwaysProvider = {
	getOne: async ({ id }) => {
		var api = new KeyTakeAwaysApi(getApiClient());
		var data = dissasembleKta(await api.getMediaKeyTakeAwaysInfo(id));
		return to_ra_data_result(data);
	},
	update: async ({ previousData, data, id }) => {
		const newPreviousData = assembleKta(previousData);
		const newData = assembleKta(data);

		var api = new KeyTakeAwaysApi(getApiClient());
		var updateResult = dissasembleKta(
			await api.setMediaKeyTakeAwaysInfo(id, {
				MediaKeyTakeAwaysModel: prepareForPost({ ...newPreviousData, ...newData })
			})
		);
		return to_ra_data_result(updateResult);
	}
};

export default keyTakeAwaysProvider;

function dissasembleKta(kta) {
	var { key_take_aways, ...rest } = kta;
	// fix data schema
	var data = key_take_aways.reduce((prev, curr, idx) => {
		prev[`kta_${idx + 1}_id`] = curr.id;
		prev[`kta_${idx + 1}_caption`] = curr.caption;
		prev[`kta_${idx + 1}_timeout`] = curr.timeout;
		return prev;
	}, rest);
	return data;
}

function assembleKta(data) {
	const { id: _id, key_take_away_title, key_take_away_timeout, ...ktas } = data || {};
	const newData = {
		id: _id,
		key_take_away_title,
		key_take_away_timeout,
		key_take_aways: [null, null, null, null, null]
	};
	[1, 2, 3, 4, 5].forEach(idx => {
		const { [`kta_${idx}_id`]: id, [`kta_${idx}_caption`]: caption, [`kta_${idx}_timeout`]: timeout } = ktas;
		newData.key_take_aways[idx - 1] = { id, caption, timeout };
	});
	return newData;
}
