import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { Chip, DialogContentText, Stack, Tooltip, Typography } from '@mui/material';
import { useMemo } from 'react';
import { NumberField, EditButton as RaEditButton, TextField, useRecordContext } from 'react-admin';
import { CustomActionColumn } from '../../components/custom-action-column';
import { OnlyTrueBooleanField } from '../../components/only-true-boolean-field';
import { WdDataGrid } from '../../components/wisdrop-data-grid';
import { SuspendUnsuspendButton } from '../customers/suspend-unsuspend-button';
import { customerDisplayName } from '../customers/utils/display-name';
import { useContactEditor } from './contact-editor-context';
import { ResendActivationEmailButton } from './resend-activation-email-button';
import { getContactDisplayName } from './utils';

const pendingActivationElement = <Chip icon={<HourglassTopIcon />} color="primary" size="small" label="Pending" />;

const ContactsGrid = props => {
	const customer = useRecordContext();
	const deleteProps = useMemo(
		() => ({
			redirect: false,
			confirmTitle: customer
				? `Deleting contact of customer ${customerDisplayName(customer)}`
				: 'Deleting contact of customer',
			confirmContent: record => <DeleteContactContent customer={customer} />
		}),
		[customer]
	);
	return (
		<WdDataGrid rowClick={null} bulkActionButtons={false} {...props}>
			<TextField source="name" label="Name" />
			<TextField source="last_name" label="Last Name" />
			<TextField source="email" label="Email" />
			<TextField source="job_title" label="Title" />
			<OnlyTrueBooleanField source="is_admin" label="Admin" />
			<OnlyTrueBooleanField source="is_counted" label="Is counted" />
			<OnlyTrueBooleanField source="is_activated" label="Activated" falseElement={pendingActivationElement} />
			<NumberField source="total_reads" label="Reads" />
			<NumberField source="slide_downloads" label="Slides" />
			<NumberField source="fullreport_downloads" label="Reports" />
			<NumberField source="searches" label="Searches" />
			<CustomActionColumn showDelete showEdit={false} deleteProps={deleteProps}>
				<SuspendUnsuspendButton />
				<ResendActivationEmailButton />
				<CustomEditButton />
			</CustomActionColumn>
		</WdDataGrid>
	);
};
const CustomEditButton = props => {
	const { openEditor } = useContactEditor();
	const record = useRecordContext(props);
	const handleEdit = e => {
		e.preventDefault();
		if (record?.id) openEditor(record.id);
	};
	return (
		<Tooltip title="Edit" placement="top">
			<div>
				<RaEditButton
					sx={{ 'padding': 0, 'minWidth': 0, '& .MuiButton-startIcon': { margin: 0 } }}
					color="edit"
					size="small"
					label={null}
					{...props}
					onClick={handleEdit}
				/>
			</div>
		</Tooltip>
	);
};

const DeleteContactContent = () => {
	const contact = useRecordContext();
	const contactName = useMemo(() => getContactDisplayName(contact, { withEmail: true }), [contact]);
	if (!contactName) return null;
	return (
		<Stack spacing={2}>
			<Typography variant="body1" component="p">
				Are you sure you want to delete this contact?
			</Typography>
			<DialogContentText component="p">{contactName}</DialogContentText>
		</Stack>
	);
};

export default ContactsGrid;
