import { UsersApi } from '@extend/paywall-api-javascript';
import { getApiClient } from '../../api/client';
import { from_ra_query, throwErrors, to_ra_data_result, to_ra_query_result } from '../../utils/result-converters';

const usersProvider = {
	getList: params => new UsersApi(getApiClient()).query({ UserQuery: from_ra_query(params) }).then(to_ra_query_result),

	create: ({ data: { email, password, role_id, is_counted } }) =>
		new UsersApi(getApiClient())
			.create({
				CreateUserCommand: {
					email: email,
					password: password,
					role: role_id,
					is_counted: is_counted
				}
			})
			.then(to_ra_data_result)
			.catch(throwErrors),

	delete: ({ id }) => new UsersApi(getApiClient()).remove(id).then(to_ra_data_result).catch(throwErrors),
	deleteMany: ({ ids }) => new UsersApi(getApiClient()).removeMany(ids).then(to_ra_data_result).catch(throwErrors),
	resendActivationEmail: ({ id }) =>
		new UsersApi(getApiClient()).resendActivationEmail(id).then(to_ra_data_result).catch(throwErrors)
};

export default usersProvider;
