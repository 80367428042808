import { blue, green, grey, red, yellow } from '@mui/material/colors';
import orange from '@mui/material/colors/orange';
import { deepmerge } from '@mui/utils';
import { defaultTheme } from 'react-admin';

const fontFamily = [
	'-apple-system',
	'BlinkMacSystemFont',
	'Roboto',
	'"Segoe UI"',
	'"Helvetica Neue"',
	'Arial',
	'sans-serif',
	'"Apple Color Emoji"',
	'"Segoe UI Emoji"',
	'"Segoe UI Symbol"'
].join(',');

export const wisdropAdminTheme = deepmerge(
	deepmerge({}, defaultTheme),
	{
		palette: {
			primary: { main: '#e52028', contrastText: '#ffffff' },
			secondary: { main: '#000000', contrastText: '#ffffff' },
			error: { main: orange[500] },
			edit: { main: blue[700] },
			delete: { main: red[700] },
			suspend: { main: yellow[600], contrastText: '#000000' },
			unsuspend: { main: green[600], contrastText: '#ffffff' },
			resend: { main: orange[800], contrastText: '#ffffff' },
			inactive: { main: grey[600], contrastText: '#000000' },
			cancelSubscription: { main: red[900], contrastText: '#ffffff' },
			undoCancelSubscription: { main: green[600], contrastText: '#ffffff' },
			overrideDefault: { main: blue[700] },
			overrideOverridden: { main: red[700] },
			overrideReadonly: { main: green[500] }
		},
		typography: {
			fontFamily: fontFamily,
			fontSize: 13
		},
		components: {
			RaReferenceField: {
				defaultProps: { link: false }
			},
			MuiButton: {
				defaultProps: {
					size: 'small'
				}
			},
			MuiFilledInput: {
				defaultProps: {
					margin: 'dense'
				}
			},
			MuiFormControl: {
				defaultProps: {
					margin: 'dense'
				}
			},
			MuiFormHelperText: {
				defaultProps: {
					margin: 'dense'
				}
			},
			MuiIconButton: {
				defaultProps: {
					size: 'small'
				}
			},
			MuiInputBase: {
				defaultProps: {
					margin: 'dense'
				}
			},
			MuiInputLabel: {
				defaultProps: {
					margin: 'dense'
				}
			},
			MuiListItem: {
				defaultProps: {
					dense: true
				}
			},
			MuiOutlinedInput: {
				defaultProps: {
					margin: 'dense'
				}
			},
			MuiFab: {
				defaultProps: {
					size: 'small'
				}
			},
			MuiTable: {
				defaultProps: {
					size: 'small'
				}
			},
			MuiTextField: {
				defaultProps: {
					margin: 'dense'
				}
			},
			MuiToolbar: {
				defaultProps: {
					variant: 'dense'
				}
			},
			MuiSwitch: {
				defaultProps: {
					size: 'small'
				}
			},
			MuiCheckbox: {
				defaultProps: {
					size: 'small'
				}
			}
		}
	},
	{ clone: true }
);
