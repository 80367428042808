import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Stack, styled, Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';
import clsx from 'clsx';
import { useMemo } from 'react';

const STYLE_PREFIX = 'WdAnalyticsSummaryItem';

const _AnalyticsSummaryItem = ({ title, value, previousValue, postFormatting, className }) => {
	const { formattedValue, trend, formattedTrend, formattedPrevValue } = useMemo(() => {
		const trend = previousValue !== 0 ? (value - previousValue) / previousValue : null;
		let _formattedValue = '-';
		if (!isNaN(value)) {
			const form = numberFormatter.format(value);
			_formattedValue = postFormatting ? postFormatting(value, form) : form;
		}

		let _formattedPrevValue = undefined;
		if (!isNaN(previousValue)) {
			const form = numberFormatter.format(previousValue);
			const postForm = postFormatting ? postFormatting(previousValue, form) : form;
			_formattedPrevValue = `previous: ${postForm}`;
		}

		let _formattedTrend;
		if (trend === null || trend === 0 || trend === undefined) {
			_formattedTrend = '';
		} else if (isNaN(trend)) {
			_formattedTrend = '-%';
		} else {
			_formattedTrend = percFormatter.format(Math.abs(trend));
		}

		return {
			formattedValue: _formattedValue,
			formattedPrevValue: _formattedPrevValue,
			trend,
			formattedTrend: _formattedTrend
		};
	}, [postFormatting, previousValue, value]);

	return (
		<Stack className={className}>
			<Typography variant="body2" className={`${STYLE_PREFIX}-title`}>
				{title}
			</Typography>
			<Typography variant="h4" className={`${STYLE_PREFIX}-value`}>
				{formattedValue}
			</Typography>
			<Typography
				variant="body2"
				title={formattedPrevValue}
				className={clsx(`${STYLE_PREFIX}-trend`, { [`${STYLE_PREFIX}-trend-negative`]: trend < 0 })}>
				{trend > 0 ? (
					<ArrowUpwardIcon fontSize="inherit" className={`${STYLE_PREFIX}-icon`} />
				) : trend < 0 ? (
					<ArrowDownwardIcon fontSize="inherit" className={`${STYLE_PREFIX}-icon`} />
				) : null}
				{formattedTrend}
			</Typography>
		</Stack>
	);
};

export const AnalyticsSummaryItem = styled(_AnalyticsSummaryItem, { name: STYLE_PREFIX, slot: 'Root' })({
	[`& .${STYLE_PREFIX}-title`]: { textAlign: 'center', whiteSpace: 'nowrap', fontSize: '0.8em' },
	[`& .${STYLE_PREFIX}-value`]: {
		textAlign: 'center',
		whiteSpace: 'nowrap',
		fontSize: '2.2em',
		fontFamily: 'Segoe UI'
	},
	[`& .${STYLE_PREFIX}-trend`]: {
		textAlign: 'center',
		whiteSpace: 'nowrap',
		color: green[300],
		fontWeight: 'bold'
	},
	[`& .${STYLE_PREFIX}-trend-negative`]: { color: red[600] },
	[`& .${STYLE_PREFIX}-icon`]: { verticalAlign: 'bottom', paddingBottom: '0.3em' }
});

var percFormatter = new Intl.NumberFormat('en-US', {
	style: 'percent',
	maximumFractionDigits: 1
});

var numberFormatter = new Intl.NumberFormat('en-US', {
	maximumFractionDigits: 1
});
