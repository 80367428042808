import { get } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useDataProvider, useRecordContext, useResourceContext } from 'react-admin';
import { useMutation, useQueryClient } from 'react-query';

export const useResourceAction = ({
	resource: inResource,
	record: inRecord,
	action,
	payload: payloadAccessor,
	mutationOptions
}) => {
	const resource = useResourceContext({ resource: inResource });
	const record = useRecordContext({ record: inRecord });
	const dataProvider = useDataProvider();
	const queryClient = useQueryClient();
	const payloadGetter = useMemo(() => {
		if (typeof payloadAccessor === 'string') return r => get(r, payloadAccessor);
		if (typeof payloadAccessor === 'function') return payloadAccessor;
		return r => r;
	}, [payloadAccessor]);

	const { onSuccess: handleSuccess, ...restOpts } = mutationOptions || {};

	const onSuccess = useCallback(
		async (...args) => {
			queryClient.invalidateQueries([resource]);
			if (typeof handleSuccess === 'function') await Promise.resolve(handleSuccess(...args));
		},
		[handleSuccess, queryClient, resource]
	);

	const mutationResult = useMutation({
		...restOpts,
		onSuccess: onSuccess,
		mutationKey: [resource, action],
		mutationFn: pl => dataProvider.execute(resource, action, pl ?? (record ? payloadGetter(record) : null))
	});

	return {
		...mutationResult,
		mutate: !record ? null : mutationResult.mutate,
		mutateAsync: !record ? null : mutationResult.mutateAsync
	};
};
