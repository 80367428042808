import { CONTACTS } from '@extend/paywall-api/lib/resource-types';
import MailIcon from '@mui/icons-material/Mail';
import { Tooltip } from '@mui/material';
import { useMemo } from 'react';
import { useNotify, useRecordContext } from 'react-admin';
import { ResourceActionButton } from '../../components/resource-action-button';

export const ResendActivationEmailButton = ({ record: inRecord, disabled, ...props }) => {
	const record = useRecordContext({ record: inRecord });

	const notify = useNotify();
	const mutationOptions = useMemo(
		() => ({
			onSuccess: () => {
				notify('Email sent', { type: 'info' });
			},
			onError: () => notify('Failed to send email', { type: 'warning' })
		}),
		[notify]
	);

	return (
		<Tooltip title="Resend Activation Email" placement="top">
			<div>
				<ResourceActionButton
					sx={{ 'padding': 0, 'minWidth': 0, '& .MuiButton-startIcon': { margin: 0 } }}
					action="resendActivationEmail"
					color={record?.is_activated ? 'inactive' : 'info'}
					icon={<MailIcon />}
					resource={CONTACTS}
					payload="user_id"
					mutationOptions={mutationOptions}
					variant="text"
					size="small"
					{...props}
				/>
			</div>
		</Tooltip>
	);
};
