import {
	ADDRESSES,
	AD_SCORES,
	AD_SCORE_MEASUREMENTS,
	AD_SCORE_SLIDES,
	ATTACHMENTS,
	BRANDS,
	CATEGORIES,
	COMPANIES,
	CONTACTS,
	COUNTRIES,
	CUSTOMERS,
	CUSTOMER_ADSCORE_ACCESSIBILITIES,
	CUSTOMER_MEDIA_ACCESSIBILITIES,
	DISTRIBUTION_PLANS,
	ENTITY_ATTACHMENTS,
	FILE,
	INDUSTRIES,
	KEY_TAKE_AWAYS,
	LEARN_MORE,
	MEDIA,
	MEDIA_KEY_TAKE_AWAYS,
	MEDIA_READ_STATS,
	MEDIA_SLIDES,
	MEDIA_STATS,
	ROLES,
	SUBSCRIPTIONS,
	SUBSCRIPTION_DISTRIBUTION_SETTINGS,
	SUBSCRIPTION_TYPES,
	TAGS,
	USERS,
	YEARS
} from '@extend/paywall-api/lib/resource-types';

import { Resource } from 'react-admin';
import WisdropDataProvider from '../api/data-provider';
import { getAdScoreResource } from './adscores';
import adScoresProvider from './adscores/data-provider';
import adScoreSlidesProvider from './adscoreslides/data-provider';
import adScoreMeasurementsProvider from './adscore_measurements/data-provider';
import { analyticsProvider, getAnalyticsResource } from './analytics';
import { ANALYTICS } from './analytics/name';
import attachmentsProvider from './attachments/data-provider';
import { blobsProvider } from './blobs';
import { BLOBS } from './blobs/name';
import { brandsProvider, getBrandsResource } from './brands';
import { categoriesProvider, getCategoriesResource } from './categories';
import { companiesProvider, getCompaniesResource } from './companies';
import { countriesProvider } from './countries';
import { customerAddressesProvider } from './customer-addresses';
import { customerAdScoresAccessibilityProvider } from './customer-adscores-accessibility';
import { contactsProvider } from './customer-contacts';
import { customerMediaAccessibilityProvider } from './customer-media-accessibility';
import { customersProvider, getCustomersResource } from './customers';
import { distPlansProvider, getDistPlansResource } from './dist-plans';
import entityAttachmentsProvider from './entityattachments/data-provider';
import { filesProvider } from './files';
import { getIndustriesResource, industriesProvider } from './industries';
import { keyTakeAwaysProvider, mediaKeyTakeAwaysProvider } from './key-take-aways';
import { getLearnMoreResource, learnMoreProvider } from './learn-more';
import { mediaProvider } from './media';
import { mediaReadStatsProvider } from './media-read-stats';
import { mediaStatsProvider } from './media-stats';
import getMediaResource from './media/admin-resource';
import { mediaSlidesProvider } from './mediaslides';
import { rolesProvider } from './roles';
import {
	getSubscriptionDistributionSettingsResource,
	subscriptionDistributionSettingsProvider
} from './subsc-dist-settings';
import { getSubscTypesResource, subscTypeProvider } from './subsc-types';
import { subscriptionsProvider } from './subscriptions';
import { getTagsResource, tagsProvider } from './tags';
import { getTouResource, touProvider } from './terms-of-use';
import { TERMS_OF_USE } from './terms-of-use/name';
import { getUsersResource, usersProvider } from './users';
import yearsProvider from './years/data-provider';

export const dataProvider = new WisdropDataProvider({
	[AD_SCORES]: adScoresProvider,
	[AD_SCORE_SLIDES]: adScoreSlidesProvider,
	[AD_SCORE_MEASUREMENTS]: adScoreMeasurementsProvider,
	[TAGS]: tagsProvider,
	[USERS]: usersProvider,
	[ROLES]: rolesProvider,
	[LEARN_MORE]: learnMoreProvider,
	[COUNTRIES]: countriesProvider,
	[ENTITY_ATTACHMENTS]: entityAttachmentsProvider,
	[DISTRIBUTION_PLANS]: distPlansProvider,
	[SUBSCRIPTION_TYPES]: subscTypeProvider,
	[INDUSTRIES]: industriesProvider,
	[COMPANIES]: companiesProvider,
	[BRANDS]: brandsProvider,
	[CATEGORIES]: categoriesProvider,
	[SUBSCRIPTION_DISTRIBUTION_SETTINGS]: subscriptionDistributionSettingsProvider,
	[TERMS_OF_USE]: touProvider,
	[ANALYTICS]: analyticsProvider,
	[CUSTOMERS]: customersProvider,
	[SUBSCRIPTIONS]: subscriptionsProvider,
	[FILE]: filesProvider,
	[BLOBS]: blobsProvider,
	[ADDRESSES]: customerAddressesProvider,
	[CONTACTS]: contactsProvider,
	[MEDIA]: mediaProvider,
	[MEDIA_SLIDES]: mediaSlidesProvider,
	[CUSTOMER_MEDIA_ACCESSIBILITIES]: customerMediaAccessibilityProvider,
	[CUSTOMER_ADSCORE_ACCESSIBILITIES]: customerAdScoresAccessibilityProvider,
	[KEY_TAKE_AWAYS]: keyTakeAwaysProvider,
	[MEDIA_KEY_TAKE_AWAYS]: mediaKeyTakeAwaysProvider,
	[MEDIA_READ_STATS]: mediaReadStatsProvider,
	[MEDIA_STATS]: mediaStatsProvider,
	[YEARS]: yearsProvider,
	[ATTACHMENTS]: attachmentsProvider
});

export const getAdminContent = permissions => [
	getMediaResource(permissions),
	getAdScoreResource(permissions),
	getCategoriesResource(permissions),
	getIndustriesResource(permissions),
	getCompaniesResource(permissions),
	getBrandsResource(permissions),
	getSubscTypesResource(permissions),
	getDistPlansResource(permissions),
	getSubscriptionDistributionSettingsResource(permissions),
	getCustomersResource(permissions),
	getUsersResource(permissions),
	getTagsResource(permissions),
	getLearnMoreResource(permissions),
	getTouResource(permissions),
	getAnalyticsResource(permissions),
	<Resource key={COUNTRIES} name={COUNTRIES} />,
	<Resource key={ROLES} name={ROLES} />,
	<Resource key={FILE} name={FILE} />,
	<Resource key={ENTITY_ATTACHMENTS} name={ENTITY_ATTACHMENTS} />,
	<Resource key={MEDIA_KEY_TAKE_AWAYS} name={MEDIA_KEY_TAKE_AWAYS} />,
	<Resource key={MEDIA_READ_STATS} name={MEDIA_READ_STATS} />,
	<Resource key={YEARS} name={YEARS} />,
	<Resource key={MEDIA_STATS} name={MEDIA_STATS} />
];
