import { Children, cloneElement } from 'react';

export const renderSingleChild = (funcOrElement, props) => {
	const onlyChild = Children.only(funcOrElement);
	if (onlyChild) {
		const newElement = cloneElement(onlyChild, { ...props, ...onlyChild.props, ...funcOrElement.props });
		return newElement;
	}
	if (!funcOrElement) return null;
	if (typeof funcOrElement === 'function') return funcOrElement(props);
	console.error('renderContent accepts only a single child, either an element or a render function');
	return null;
};
