import { useCallback, useState } from 'react';

export const useModalUpsert = initialEditingId => {
	const [id, setId] = useState(initialEditingId);
	const [open, setOpen] = useState(false);

	const onClose = useCallback(() => {
		setOpen(false);
		setId(null);
	}, []);

	const openEditor = useCallback(id => {
		if (id == null || id === '') setId(null);
		else setId(id);
		setOpen(true);
	}, []);

	const openCreator = useCallback(() => {
		setId(null);
		setOpen(true);
	}, []);

	return { open, id, onClose, openEditor, openCreator };
};
