import { useLayoutEffect, useRef } from 'react';

export const useCallbackRef = (callback, isAsync) => {
	const callbackRef = useRef(callback);
	// set the callback to the ref everytime it changes
	useLayoutEffect(() => {
		callbackRef.current = callback;
		return () => (callbackRef.current = null);
	}, [callback]);
	return callbackRef;
};
