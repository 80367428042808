import { LEARN_MORE } from '@extend/paywall-api/lib/resource-types';
import IconContactSupport from '@mui/icons-material/ContactSupport';
import { lazy } from 'react';
import { Resource } from 'react-admin';

const LearnMoreIndex = lazy(() => import(/* webpackChunkName: "learnmore-list" */ './list.js'));

const getLearnMoreResource = perms => (
	<Resource
		key={LEARN_MORE}
		name={LEARN_MORE}
		options={{ label: 'Interest Requests' }}
		list={LearnMoreIndex}
		icon={IconContactSupport}
	/>
);
export default getLearnMoreResource;
