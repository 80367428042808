import { deepmerge } from '@mui/utils';
import { DatePicker } from '@mui/x-date-pickers';
import { useCallback, useMemo } from 'react';
import { useDateAdapter } from '../app-localization-provider';

/**
 * MuiDatePicker expects value to be a string in format 'yyyy-MM-dd'.
 * OnChange reports the date as string in the same format
 */
export const MuiDatePicker = ({ value, onChange, parseFormat, componentsProps, ...props }) => {
	const [date, handleDateChange] = useAdaptStringDateToDate({ value, format: parseFormat, onChange });
	const finalComponentsProps = useMemo(
		() => deepmerge(componentsProps || {}, { actionBar: { actions: ['clear', 'today', 'cancel', 'accept'] } }),
		[componentsProps]
	);
	return <DatePicker value={date} onChange={handleDateChange} componentsProps={finalComponentsProps} {...props} />;
};

MuiDatePicker.defaultProps = {
	inputFormat: 'dd/MM/yyyy',
	parseFormat: 'yyyy-MM-dd'
};

/**
 * Adapts a date state from any input type to Date object (output value). If format is a function then the output value is of the type returned by that function.
 * OnChange event receives a string formatted date as an argument in case format is of type string. If format is a function, onChange receives the return value of that function as argument
 */
export const useAdaptStringDateToDate = ({ value, onChange, format }) => {
	const dateAdapter = useDateAdapter();
	const finalValue = useMemo(() => {
		const parsed =
			value == null || value === ''
				? null
				: typeof format === 'function'
				? format(value, 'parse')
				: dateAdapter.date(value) || null;
		return parsed;
	}, [dateAdapter, format, value]);

	const handleChange = useCallback(
		val => {
			if (onChange) {
				const formatted = val
					? typeof format === 'function'
						? format(val, 'format')
						: dateAdapter.formatByString(val, format)
					: null;
				onChange(formatted);
			}
		},
		[onChange, dateAdapter, format]
	);
	return [finalValue, handleChange];
};
