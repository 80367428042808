import { useMemo } from 'react';
import { CreateBase, EditBase, useNotify } from 'react-admin';
import { useQueryClient } from 'react-query';
import { WdDialog, WdDialogTitle } from './wisdrop-dialog';

export const WisdropModalUpsert = ({
	id,
	resource,
	record,
	hasEdit,
	hasShow,
	title,
	mutationMode,
	mutationOptions,
	disableAuthentication,
	queryOptions,
	transform,
	defaultValues,
	children,
	redirect,
	onClose,
	open,
	...props
}) => {
	const qc = useQueryClient();
	const notify = useNotify();
	const undoable = mutationMode === 'undoable';
	const successMessage = id ? 'ra.notification.updated' : 'ra.notification.created';

	const actualMutationOptions = useMemo(
		() => ({
			onSuccess: () => {
				qc.invalidateQueries([resource]);
				notify(successMessage, {
					messageArgs: { smart_count: 1 },
					undoable
				});
				onClose();
			},
			...mutationOptions
		}),
		[mutationOptions, notify, onClose, qc, resource, successMessage, undoable]
	);

	return (
		<WdDialog onClose={onClose} open={open} {...props}>
			{open ? (
				id ? ( // We don't render the editor if there is no id yet. This is a workaround around a 'react-admin' bug where passing id=null to the EditBase would trigger a redirect to "list" upon modal close
					<EditBase
						id={id}
						disableAuthentication={disableAuthentication}
						resource={resource}
						redirect={redirect === undefined ? false : redirect}
						mutationMode={mutationMode ?? 'pessimistic'}
						mutationOptions={actualMutationOptions}
						queryOptions={queryOptions}
						transform={transform}>
						<WdDialogTitle title={title} />
						{children}
					</EditBase>
				) : (
					<CreateBase
						disableAuthentication={disableAuthentication}
						hasEdit={hasEdit}
						hasShow={hasShow}
						resource={resource}
						record={record}
						redirect={redirect === undefined ? false : redirect}
						mutationMode={mutationMode ?? 'pessimistic'}
						mutationOptions={actualMutationOptions}
						queryOptions={queryOptions}
						transform={transform}>
						<WdDialogTitle title={title} />
						{children}
					</CreateBase>
				)
			) : null}
		</WdDialog>
	);
};

WisdropModalUpsert.defaultProps = { maxWidth: 'sm', fullWidth: true, redirect: false };
