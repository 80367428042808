import { StatsApi } from '@extend/paywall-api-javascript';
import { getApiClient } from '../../api/client';
import { to_ra_query_result } from '../../utils/result-converters';

const analyticsProvider = {
	getList: async ({ filter }) => {
		const payload = { AnalyticsSummaryRequest: filter };
		var api = new StatsApi(getApiClient());
		const [userActions, metrics] = await Promise.all([
			api.getAnalyticsUserActions(payload),
			api.getAnalyticsKpis(payload)
		]);

		return to_ra_query_result([
			{ id: 'userActions', data: userActions },
			{ id: 'metrics', data: metrics }
		]);
	}
};

export default analyticsProvider;
