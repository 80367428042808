export const custTypes = [
	{
		id: 'Retail',
		name: 'Solo'
	},
	{
		id: 'Business',
		name: 'Business'
	}
];
