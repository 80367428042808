import { Box, Card, CardContent, CardHeader, Stack, styled } from '@mui/material';
import { red } from '@mui/material/colors';
import useResizeObserver from '@react-hook/resize-observer';
import { addDays, differenceInDays } from 'date-fns';
import dfnsAdd from 'date-fns/add';
import dfnsFormat from 'date-fns/format';
import { groupBy, map } from 'lodash';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { ListBase, Title, useListContext } from 'react-admin';
import { Bar, BarChart, LabelList, Tooltip, XAxis, YAxis } from 'recharts';
import { DefaultActions } from '../../components/default-actions-toolbar';
import { datify } from '../../utils/datify';
import { analyticsConfig, chartsOrder } from './analytics-configuration';
import { analyticsFilter } from './filter';
import { Metrics } from './metrics';
import { ANALYTICS } from './name';
import { AnalyticsToolbar } from './toolbar';

/** observes and reports the size of the target element. Target must be a ref attached to a DOM element */
const useSize = (target, opts) => {
	const [size, setSize] = useState({});
	useLayoutEffect(() => {
		if (target.current) setSize(target.current.getBoundingClientRect());
	}, [target]);
	useResizeObserver(target, entry => setSize(entry.contentRect));
	return size;
};

const MY_BARCHAR_PREFIX = 'WdBarChart';
const MyBarChart = styled(
	({ classes, className, style, width, height, ...props }) => {
		const containerRef = useRef();
		const size = useSize(containerRef);

		return (
			<div className={className} style={{ ...style, width, height }}>
				<div className={`${MY_BARCHAR_PREFIX}-container`} ref={containerRef}>
					<BarChart {...props} width={size.width} height={size.height} />
				</div>
			</div>
		);
	},
	{ name: MY_BARCHAR_PREFIX, slot: 'Root' }
)({
	width: '100%',
	position: 'relative',
	[`& .${MY_BARCHAR_PREFIX}-container`]: { position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }
});

const CustomLabel = ({ x, y, width, value }) => {
	if (!value) return null;

	return (
		<text x={x + width / 2} y={y} dy={-6} fill="#000000" fontWeight="bold" fontSize="inherit" textAnchor="middle">
			{value}
		</text>
	);
};

const ChartItem = ({ analyticsId, data }) => {
	const { title, chart, chartKey, chartData, tooltip } = analyticsConfig[analyticsId] || {};

	const finalData = useMemo(() => {
		if (!chartData) return data;
		return data.map(chartData);
	}, [chartData, data]);

	const domain = useMemo(() => {
		const maxValue = Math.max(...finalData.map(i => i.value));
		return [0, maxValue + maxValue / 9];
	}, [finalData]);

	if (!chart) return null;

	return (
		<Card elevation={2} sx={{ overflow: 'visible' }}>
			<CardHeader title={title} />
			<CardContent>
				<MyBarChart height={250} data={finalData}>
					<XAxis dataKey="formattedDate" interval="equidistantPreserveStart" />
					<YAxis minTickGap={1} interval="equidistantPreserveStart" domain={domain} />
					<Tooltip content={tooltip} />
					<Bar dataKey={chartKey || 'value'} fill={red[600]}>
						<LabelList dataKey={chartKey || 'value'} position="top" content={<CustomLabel />} />
					</Bar>
				</MyBarChart>
			</CardContent>
		</Card>
	);
};

export const Charts = () => {
	const {
		data: [{ data: userActions } = {}] = [],
		filterValues: { from, to }
	} = useListContext();

	const prevData = useRef([]);
	const data = useMemo(() => {
		try {
			if (Array.isArray(userActions)) {
				prevData.current = createData(userActions, { start: datify(from), end: datify(to) });
			}
		} catch (e) {
			console.error(e);
		}
		return prevData.current;
	}, [userActions, from, to]);
	return chartsOrder.map(analyticsId => <ChartItem analyticsId={analyticsId} key={analyticsId} data={data} />);
};

const createData = (userActions, { start, end }, interval = 7) => {
	var allGroups = Array.from({ length: Math.floor(differenceInDays(end, start) / interval) + 1 }, (_, i) => i);

	const userActionMap = groupBy(
		map(userActions, i => ({ ...i })),
		item => Math.floor(differenceInDays(datify(item.action_date), start) / interval)
	);

	return allGroups.map(groupNum => {
		let actions = userActionMap[groupNum] || [];
		const firstDate = addDays(start, groupNum * interval);
		const lastDate = addDays(start, (groupNum + 1) * interval - 1);
		var groupKey = `${dfnsFormat(firstDate, 'd/M')} - ${dfnsFormat(lastDate, 'd/M')}`;
		return {
			id: groupNum,
			firstDate: firstDate,
			lastDate: lastDate,
			formattedDate: groupKey,
			userActions: actions,
			value: actions.length
		};
	});
};

const defaultFilters = {
	countryId: 'GR',
	from: dfnsFormat(dfnsAdd(new Date(), { months: -3 }), 'yyyy-MM-dd'),
	to: dfnsFormat(new Date(), 'yyyy-MM-dd')
};

const analyticsActions = (
	<DefaultActions
		showCreateButton={false}
		excelExport={{
			url: '/api/v1/stats/analytics/useractions/export',
			// fileName: 'User Activity.xlsx',
			mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			label: 'Export User Activity'
		}}
	/>
);

export const AnalyticsPage = () => {
	return (
		<ListBase resource={ANALYTICS} filterDefaultValues={defaultFilters}>
			<Title title="Analytics" />
			<AnalyticsToolbar filters={analyticsFilter} actions={analyticsActions} />
			<Stack spacing={3} sx={{ marginTop: 1 }}>
				<Metrics />
				<Charts />
			</Stack>
			<Box sx={{ minHeight: '30%' }} />
		</ListBase>
	);
};

export default AnalyticsPage;
