import { FileApi } from '@extend/paywall-api-javascript';
import { getApiClient } from '../../api/client';
import { from_ra_query, throwErrors, to_ra_data_result, to_ra_query_result } from '../../utils/result-converters';

export const fileProvider = {
	getList: params => new FileApi(getApiClient()).query({ FileQuery: from_ra_query(params) }).then(to_ra_query_result),
	getMany: ({ ids }) => new FileApi(getApiClient()).getMany({ request_body: ids }).then(to_ra_data_result),
	getOne: ({ id }) => new FileApi(getApiClient()).get(id).then(to_ra_data_result),
	create: async ({ data: { file } }) => {
		try {
			const api = new FileApi(getApiClient());
			const files = await api.upload({ files: [file.rawFile] });
			if (!files || files.length < 1) throw new Error('Unable to upload file', file);
			return to_ra_data_result(files[0]);
		} catch (e) {
			return throwErrors(e);
		}
	}
};
export default fileProvider;
