import { Children, cloneElement, isValidElement, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

export const FieldWatcher = ({ name, defaultValue, disabled, exact, control, connect, children }) => {
	const value = useWatch({ name, defaultValue, disabled, exact, control });

	const connectFunc = useMemo(() => {
		if (typeof connect === 'string' && connect) return (val, props) => ({ [connect]: val, ...props });
		if (typeof connect === 'function') return connect;
		return (val, props) => ({ [name]: val, ...props });
	}, [connect, name]);

	if (typeof children === 'function') return children(value);

	const onlyChild = Children.only(children);

	if (onlyChild) return cloneElement(onlyChild, connectFunc(value, onlyChild.props));

	return Children.map(children, child => {
		if (typeof child === 'function') return child(value);
		if (isValidElement(child)) return cloneElement(child, connectFunc(value, child.props));
	});
};
