import { ERROR_INVALID_TYPE, validateCsvDomainNames, validateCsvEmails } from './validation';

export const validateForm = ({
	email,
	name,
	last_name,
	customer_type,
	afm,
	doy,
	job_description,
	is_counted,
	auto_renewal,
	email_domains,
	email_whitelist
}) => {
	var errors = {};

	if (auto_renewal !== true && auto_renewal !== false) errors.auto_renewal = 'Auto renew is required';

	if (!email) errors.email = 'Email is required';

	if (!name) errors.name = 'Name is required';

	if (!last_name) errors.last_name = 'Last Name is required';

	if (!customer_type) errors.customer_type = 'Type date is required';

	if (customer_type === 'Business') {
		if (!afm) errors.afm = 'AFM is required';
		if (!doy) errors.doy = 'DOY is required';

		if (!job_description) errors.job_description = 'Job description is required';

		// if (!addresses || addresses.length === 0) errors.addresses = 'You must enter at least one address';
	}

	if (is_counted !== true && is_counted !== false) errors.doy = 'Required';

	var domainsTest = validateCsvDomainNames(email_domains);
	if (domainsTest) {
		if (domainsTest.errorType === ERROR_INVALID_TYPE) {
			errors.email_domains = 'Value should be a string';
			if (process.env.NODE_ENV !== 'production')
				console.warn('Value should be a string (domain names csv)', domainsTest);
		} else errors.email_domains = `Invalid domain names: '${domainsTest.payload.join("', '")}'`;
	}
	var emailWhiteListTest = validateCsvEmails(email_whitelist);
	if (emailWhiteListTest) {
		if (emailWhiteListTest.errorType === ERROR_INVALID_TYPE) {
			errors.email_whitelist = 'Value should be a string';
			if (process.env.NODE_ENV !== 'production')
				console.warn('Value should be a string (emails whitelist csv)', emailWhiteListTest);
		} else errors.email_whitelist = `Invalid email addresses: '${emailWhiteListTest.payload.join("', '")}'`;
	}

	return errors;
};
