import { INDUSTRIES } from '@extend/paywall-api/lib/resource-types';
import IconLabel from '@mui/icons-material/Label';
import { lazy } from 'react';
import { Resource } from 'react-admin';

const IndustriesIndex = lazy(() => import(/* webpackChunkName: "industries-list" */ './list.js'));
const IndustryCreator = lazy(() => import(/* webpackChunkName: "industries-creator" */ './creator.js'));
const IndustryEditor = lazy(() => import(/* webpackChunkName: "industries-editor" */ './editor.js'));

const getIndustriesResource = perms => (
	<Resource
		key={INDUSTRIES}
		name={INDUSTRIES}
		options={{ label: 'Industries' }}
		list={IndustriesIndex}
		edit={IndustryEditor}
		create={IndustryCreator}
		icon={IconLabel}
	/>
);
export default getIndustriesResource;
