import { getApiBasePath } from '../api/client';

export class DownloadImageCache {
	constructor(tokenResolver, notifyExpiration) {
		const _cache = {};

		const _expireItem = (url, item, notify) => {
			if (!item) item = _cache[url];
			if (item) {
				if (item.timeout) {
					clearTimeout(item.timeout);
					item.timeout = null;
				}
				item.canceled = true;
				item.expired = true;
				if (item.url) {
					URL.revokeObjectURL(item.url);
					item.url = null;
				}
			}
			if (_cache[url]) delete _cache[url];
			if (!(notify === false)) notifyExpiration?.(url);
		};

		const _createDownloadPromise = url => {
			const item = {};
			_cache[url] = item;
			item.timeout = setTimeout(() => _expireItem(url, item), 5 * 60 * 1000); // 5 mins sliding timeout

			var token = tokenResolver();
			const fetchParams = { headers: { Accept: 'application/octet-stream' } };
			if (token) {
				fetchParams.headers['Authorization'] = `Bearer ${token}`;
			}

			item.promise = new Promise((resolve, reject) =>
				fetch(`${getApiBasePath()}${url}`, fetchParams)
					.then(async r => {
						if (item.canceled) throw new Error(`Download image canceled: ${url}`);
						if (r.ok) return await r.blob();
						else {
							const err = new Error(r.statusText);
							err.status = r.status;
							console.error(`Download image error: ${url}`, await r.text());
							throw err;
						}
					})
					.then(b => {
						if (item.canceled) throw new Error(`Download image canceled: ${url}`);
						const finalUrl = URL.createObjectURL(b);
						item.url = finalUrl;
						resolve(finalUrl);
					})
					.catch(reject)
			);

			return item.promise;
		};

		this.downloadImage = async url => {
			try {
				const item = _cache[url];
				if (!item || item.expired || item.canceled) {
					return await _createDownloadPromise(url);
				} else {
					clearTimeout(item.timeout);
					item.timeout = setTimeout(() => _expireItem(url, item), 5 * 60 * 1000); // 5 mins sliding timeout
					if (item.url) {
						return item.url;
					}
					return await item.promise;
				}
			} catch (e) {
				_expireItem(url, undefined, false);
				throw e;
			}
		};

		this.cleanUpCache = () => {
			notifyExpiration?.();
			for (const url in _cache) _expireItem(url, _cache[url]);
		};
	}
}

export default DownloadImageCache;

/**
 * Cache item is of type
 * {
 * 	promise: Promise // a promise showing that right now an image is downloading
 *    timeout: int // a timeout handler for expiring the downloaded image
 *    url: string // the resolved blob url,
 * 	expired: boolean // true if the cache is expired
 * }
 */
