import { Stack } from '@mui/material';
import { useMemo } from 'react';
import { useListContext } from 'react-admin';
import { analyticsConfig, metricsOrder } from './analytics-configuration';
import { AnalyticsSummaryItem } from './summary-item';

export const Metrics = () => {
	const { data: [, { data: metrics } = {}] = [] } = useListContext();

	const content = useMemo(
		() => metricsOrder.map(metric => <MetricItem metric={metric} metrics={metrics} key={metric} />),
		[metrics]
	);

	return (
		<Stack justifyContent="space-around" direction="row" spacing={8}>
			{content}
		</Stack>
	);
};

const MetricItem = ({ metric, metrics }) => {
	const { title, value, prevValue, postFormatting } = useMemo(() => {
		const { title, valueProcessing, postFormatting } = analyticsConfig[metric] ?? {};

		let value_;
		let prevValue_;

		const values = (metrics || {})[metric];

		if (!!values) {
			const { value, previousVal } = values;
			if (valueProcessing) {
				value_ = valueProcessing(value);
				prevValue_ = valueProcessing(previousVal);
			} else {
				value_ = value;
				prevValue_ = previousVal;
			}
		}
		return {
			title,
			value: value_,
			prevValue: prevValue_,
			postFormatting
		};
	}, [metric, metrics]);
	if (!title) return null;

	return (
		<AnalyticsSummaryItem title={title} value={value} previousValue={prevValue} postFormatting={postFormatting} />
	);
};
