import { CompaniesApi } from '@extend/paywall-api-javascript';
import { getApiClient } from '../../api/client';
import {
	from_ra_query,
	prepareForPost,
	throwErrors,
	to_ra_data_result,
	to_ra_query_result
} from '../../utils/result-converters';

const companiesProvider = {
	getList: async params =>
		new CompaniesApi(getApiClient()).query({ CompanyQuery: from_ra_query(params) }).then(to_ra_query_result),
	getMany: ({ ids }) => new CompaniesApi(getApiClient()).getMany({ request_body: ids }).then(to_ra_data_result),
	getOne: ({ id }) => new CompaniesApi(getApiClient()).get(id).then(to_ra_data_result),
	create: ({ data }) =>
		new CompaniesApi(getApiClient())
			.create({ CreateCompanyCommand: prepareForPost(data) })
			.then(to_ra_data_result)
			.catch(throwErrors),
	update: ({ previousData, data, id }) =>
		new CompaniesApi(getApiClient())
			.update(id, { UpdateCompanyCommand: prepareForPost({ ...previousData, ...data }) })
			.then(to_ra_data_result)
			.catch(throwErrors),
	delete: ({ id }) => new CompaniesApi(getApiClient()).remove(id).then(to_ra_data_result).catch(throwErrors),
	deleteMany: ({ ids }) => new CompaniesApi(getApiClient()).removeMany(ids).then(to_ra_data_result).catch(throwErrors)
};

export default companiesProvider;
