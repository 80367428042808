import { AuthImageField } from '../auth-image-field';
import { BackgroundImage } from '../background-image';
import RenderIfVisible from 'react-render-if-visible';

export const UploadedImageField = ({ children, sx, ...props }) => (
	<RenderIfVisible defaultHeight={96} visibleOffset={20}>
		<AuthImageField
			source="file_url"
			options={{ type: 'small', width: 128, height: 96 }}
			style={{ maxWidth: '128px' }}
			{...props}>
			{children ? (
				children
			) : (
				<BackgroundImage sx={{ aspectRatio: '16 / 9', width: '128px', backgroundSize: 'cover', ...sx }} />
			)}
		</AuthImageField>
	</RenderIfVisible>
);
