import { SUBSCRIPTIONS } from '@extend/paywall-api/lib/resource-types';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import UndoIcon from '@mui/icons-material/Undo';
import { useCallback, useMemo } from 'react';
import { useNotify, useRecordContext } from 'react-admin';
import { ResourceActionButton } from '../../components/resource-action-button';

export const SubscriptionCancellationButton = ({ record, disabled, ...props }) => {
	const subscription = useRecordContext({ record });
	const isCancelled = !!subscription?.is_cancelled;
	const notify = useNotify();
	const mutationOptions = useMemo(
		() =>
			isCancelled
				? {
						onSuccess: () => notify('Subscription cancellation reverted', { type: 'info' }),
						onError: () => notify('Unable to undo subscription cancellation', { type: 'error' })
				  }
				: {
						onSuccess: () => notify('Subscription cancelled', { type: 'info' }),
						onError: () => notify('Unable to cancel subscription', { type: 'error' })
				  },
		[isCancelled, notify]
	);

	const payload = useCallback(s => ({ id: s?.id }), []);

	if (!subscription) return null;
	return (
		<ResourceActionButton
			label={isCancelled ? 'Undo Cancel' : 'Cancel'}
			action={isCancelled ? 'undoCancel' : 'cancel'}
			color={isCancelled ? 'undoCancelSubscription' : 'cancelSubscription'}
			icon={isCancelled ? <UndoIcon /> : <RemoveShoppingCartIcon />}
			resource={SUBSCRIPTIONS}
			payload={payload}
			mutationOptions={mutationOptions}
			variant="contained"
			size="small"
			{...props}
		/>
	);
};
