import { Card, CardContent, CardHeader, styled, Typography } from '@mui/material';
import filterWhere from 'lodash/filter';
import groupBy from 'lodash/groupBy';
import { memo } from 'react';

import dfnsFormat from 'date-fns/format';

const TooltipCard = styled(Card)({ 'backgroundColor': 'black', '& *': { color: 'white' } });
const TooltipContent = styled(CardContent)({ padding: 12, paddingBottom: 4 });
const TooltipHeader = styled(CardHeader)({ padding: 12, paddingBottom: 4 });

const ClientsVisitedTooltip = ({ active, payload, label }) => {
	if (active && Array.isArray(payload) && payload.length > 0) {
		const { payload: data } = payload[0];
		const { clientsVisited, value, firstDate, lastDate } = data;
		if (value > 0) {
			return (
				<TooltipCard elevation={3}>
					<TooltipHeader
						title={`${dfnsFormat(firstDate, 'd MMM')} - ${dfnsFormat(lastDate, 'd MMM')}, ${value} Client${
							value !== 1 ? 's' : ''
						}`}
					/>
					<TooltipContent>
						{clientsVisited.map(info => (
							<Typography>{info.client}</Typography>
						))}
					</TooltipContent>
				</TooltipCard>
			);
		}
	}
	return null;
};

const UserDisplayInfo = memo(({ last_name, first_name, email, client }) => {
	let nameParts = [];
	if (last_name || first_name) {
		if (last_name) nameParts.push(last_name);
		if (first_name) nameParts.push(first_name);
	} else {
		nameParts.push(email);
	}
	if (client) nameParts.push(`(${client})`);
	const content = nameParts.join(' ');
	return <Typography>{content}</Typography>;
});

const UsersVisitedTooltip = ({ active, payload, label }) => {
	if (active && Array.isArray(payload) && payload.length > 0) {
		const { payload: data } = payload[0];
		const { usersVisited, value, firstDate, lastDate } = data;
		if (value) {
			return (
				<TooltipCard elevation={3}>
					<TooltipHeader
						title={`${dfnsFormat(firstDate, 'd MMM')} - ${dfnsFormat(lastDate, 'd MMM')}, ${value} User${
							value !== 1 ? 's' : ''
						}`}
					/>
					<TooltipContent>
						{usersVisited.map(({ id, last_name, first_name, email, client }) => (
							<UserDisplayInfo
								key={id}
								last_name={last_name}
								first_name={first_name}
								email={email}
								client={client}
							/>
						))}
					</TooltipContent>
				</TooltipCard>
			);
		}
	}
	return null;
};

const UsersActivatedTooltip = ({ active, payload, label, ...rest }) => {
	if (active && Array.isArray(payload) && payload.length > 0) {
		const { payload: data } = payload[0];
		const { userActivations, value, firstDate, lastDate } = data;

		if (value) {
			return (
				<TooltipCard elevation={3}>
					<TooltipHeader
						title={`${dfnsFormat(firstDate, 'd MMM')} - ${dfnsFormat(lastDate, 'd MMM')}, ${value} User${
							value !== 1 ? 's' : ''
						}`}
					/>
					<TooltipContent>
						{userActivations.map(({ id, last_name, first_name, email, client }) => (
							<UserDisplayInfo
								key={id}
								last_name={last_name}
								first_name={first_name}
								email={email}
								client={client}
							/>
						))}
					</TooltipContent>
				</TooltipCard>
			);
		}
	}
	return null;
};

export const analyticsConfig = {
	users_activated: {
		title: 'Activated Users',
		chart: true,
		chartKey: 'value',
		chartData: dayInfo => {
			const { userActions } = dayInfo;
			const userActivationActions = filterWhere(userActions, item => item.action === 'USER_ACTIVATED');
			const perUser = groupBy(userActivationActions, 'user_id');
			const userInfo = Object.keys(perUser).map(userId => ({ ...perUser[userId][0] }));
			return { ...dayInfo, userActivations: userInfo || [], value: userInfo?.length || 0 };
		},
		tooltip: <UsersActivatedTooltip />
	},
	clients_visited: {
		title: 'Clients Visited',
		chart: true,
		chartKey: 'value',
		chartData: dayInfo => {
			const { userActions } = dayInfo;
			const userSignInActions = filterWhere(userActions, item => item.action === 'SIGN_IN');
			const perClient = groupBy(userSignInActions, 'customer_id');
			const clientInfo = Object.keys(perClient).map(customerId => ({ ...perClient[customerId][0] }));
			return { ...dayInfo, clientsVisited: clientInfo || [], value: clientInfo?.length || 0 };
		},
		tooltip: <ClientsVisitedTooltip />
	},
	users_visited: {
		title: 'Users Visited',
		chart: true,
		chartKey: 'value',
		chartData: dayInfo => {
			const { userActions } = dayInfo;
			const userActivationActions = filterWhere(userActions, item => item.action === 'SIGN_IN');
			const perUser = groupBy(userActivationActions, 'user_id');
			const userInfo = Object.keys(perUser).map(userId => ({ ...perUser[userId][0] }));
			return { ...dayInfo, usersVisited: userInfo || [], value: userInfo?.length || 0 };
		},
		tooltip: <UsersVisitedTooltip />
	},

	paying_clients: { title: 'Paying Clients' },
	arr: { title: 'ARR (1,000€)' },
	users_per_paying_client: { title: 'Users/Client' },
	logins_per_user: { title: 'Logins/User' },
	content_per_user: { title: 'Content/User' },
	slides_per_paying_client: { title: 'Slides↓/Client' },
	reports_per_paying_client: { title: 'Reports↓/Client' },
	trial_clients: { title: 'Free Trials' },
	users_per_trial_client: { title: 'Users/Trialist' },
	slides_per_trial_client: { title: 'Slides↓/Trialist' }
};

export const metricsOrder = [
	'paying_clients',
	'arr',
	'users_per_paying_client',
	'logins_per_user',
	'content_per_user',
	'slides_per_paying_client',
	'reports_per_paying_client',
	'trial_clients',
	'users_per_trial_client',
	'slides_per_trial_client'
];

export const chartsOrder = ['users_activated', 'clients_visited', 'users_visited'];
