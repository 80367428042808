import { AD_SCORES } from '@extend/paywall-api/lib/resource-types';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { lazy } from 'react';
import { Resource } from 'react-admin';
import AdScoreCreator from './creator';

const AdScoresIndex = lazy(() => import(/* webpackChunkName: "adscores-list" */ './list'));
const AdScoresEditor = lazy(() => import(/* webpackChunkName: "adscores-editor" */ './editor'));

const getAdScoreResource = perms => (
	<Resource
		key={AD_SCORES}
		name={AD_SCORES}
		options={{ label: 'Ad Scores' }}
		list={AdScoresIndex}
		edit={AdScoresEditor}
		create={AdScoreCreator}
		icon={OndemandVideoIcon}
		recordRepresentation="campaign"
	/>
);

export default getAdScoreResource;
