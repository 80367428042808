import { AdScoresApi } from '@extend/paywall-api-javascript';
import { getApiClient, uploadFile } from '../../api/client';
import { to_ra_query_result } from '../../utils/result-converters';

const adScoreMeasurementsProvider = {
	create: async ({ data: { adscore_id, content } }) => {
		await uploadFile(content.rawFile, `/api/v1/adscores/${adscore_id}/measurements`);
		return { data: { id: adscore_id, uploaded: true } };
	},

	getManyReference: ({ target, id, ...params }) => {
		switch (target) {
			case 'adscore_id':
				return new AdScoresApi(getApiClient())
					.getMeasurements(id)
					.then(result =>
						to_ra_query_result((result || []).map(r => ({ ...r, id: `${r.adscore_id}|${r.attr_id}` })))
					);
			default:
				return Promise.resolve([]);
		}
	}
};

export default adScoreMeasurementsProvider;
