import { AttachmentsApi } from '@extend/paywall-api-javascript';
import { getApiClient } from '../../api/client';
import { to_ra_data_result } from '../../utils/result-converters';

const attachmentsProvider = {
	getMany: ({ ids, dynamicParams }) =>
		new AttachmentsApi(getApiClient()).getMany(dynamicParams[0], { request_body: ids }).then(to_ra_data_result),
	getOne: ({ id, dynamicParams }) =>
		new AttachmentsApi(getApiClient()).get(dynamicParams[0], id).then(to_ra_data_result)
};

export default attachmentsProvider;
