import { CustomersApi, MediaApi } from '@extend/paywall-api-javascript';
import { AD_SCORES, MEDIA } from '@extend/paywall-api/lib/resource-types';
import { getApiClient } from '../../api/client';
import {
	from_ra_query,
	prepareForPost,
	throwErrors,
	to_ra_data_result,
	to_ra_query_result
} from '../../utils/result-converters';

const customersProvider = {
	getList: params =>
		new CustomersApi(getApiClient()).query({ CustomersQuery: from_ra_query(params) }).then(to_ra_query_result),
	getMany: ({ ids }) => new CustomersApi(getApiClient()).getMany({ request_body: ids }).then(to_ra_data_result),
	getManyReference: ({ target, id, ...params }) => {
		switch (target) {
			case 'available_to_media_id':
				return new MediaApi(getApiClient())
					.queryCustomers(id, { CustomersQuery: from_ra_query(params) })
					.then(to_ra_query_result);
			default:
				return null;
		}
	},
	getOne: async ({ id, meta }) => {
		const api = new CustomersApi(getApiClient());
		var res = await api.get(id);
		if (meta?.edit) {
			const { available_in_country_ids, tag_ids } = await api.getOneToManyReferences(id);
			// get thumbnail

			res = {
				...res,
				available_in_country_ids: (available_in_country_ids || []).map(i => i.id || i),
				tag_ids: (tag_ids || []).map(i => i.id || i)
			};
		}
		return to_ra_data_result(res);
	},

	create: async ({ data }) =>
		new CustomersApi(getApiClient()).create({ CreateCustomerCommand: prepareForPost(data) }).then(to_ra_data_result),

	update: async ({ previousData, data, id }) =>
		new CustomersApi(getApiClient())
			.update(id, { UpdateCustomerCommand: prepareForPost({ ...previousData, ...data }) })
			.then(to_ra_data_result),
	delete: ({ id }) => new CustomersApi(getApiClient()).remove(id).then(to_ra_data_result).catch(throwErrors),

	deleteMany: ({ ids }) => new CustomersApi(getApiClient()).removeMany(ids).then(to_ra_data_result).catch(throwErrors),

	suspend: ({ id }) => {
		let _api = CustomersApi;
		var adaptor = new _api(getApiClient());
		return adaptor.suspendMany([id]).then(to_ra_data_result).catch(throwErrors);
	},
	unsuspend: ({ id }) => {
		var api = new CustomersApi(getApiClient());
		return api.unsuspendMany([id]).then(to_ra_data_result).catch(throwErrors);
	},
	overrideAccessibility: ({ customer_id, resource, ...params }) => {
		if (resource === MEDIA) {
			let api = new CustomersApi(getApiClient());
			return api
				.changeMediaAccessibility(customer_id, { CommandCustomerChangeMediaAccessibility: params })
				.then(to_ra_data_result)
				.catch(throwErrors);
		}
		if (resource === AD_SCORES) {
			let api = new CustomersApi(getApiClient());
			return api
				.changeAdScoresAccessibility(customer_id, { CommandCustomerChangeAdScoresAccessibility: params })
				.then(to_ra_data_result)
				.catch(throwErrors);
		}
		return null;
	}
};

export default customersProvider;
