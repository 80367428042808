import ReplayIcon from '@mui/icons-material/Replay';
import { styled } from '@mui/material';
import { memo, useCallback } from 'react';
import { Button, SaveButton, Toolbar, useCreateContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { BackButton } from '../cancel-button';

export const FormToolbar = styled(
	({ save = true, back, cancel, reset, saveOpts = {}, cancelOpts = {}, resetOpts = {}, children, ...props }) => {
		const { save: create } = useCreateContext();
		const isCreate = !!create;
		return (
			<Toolbar {...props}>
				{save ? <SaveButton size="medium" {...saveOpts} /> : null}
				{(reset === undefined && !isCreate) || reset ? <ResetButton {...resetOpts} /> : null}
				{cancel ? <BackButton variant="outlined" {...cancelOpts} /> : null}
				{children}
			</Toolbar>
		);
	}
)(({ theme }) => ({ '& .MuiButton-root': { marginRight: theme.spacing(1) } }));

export const ResetButton = memo(({ onClick, icon, ...props }) => {
	const { reset } = useFormContext();
	const handleClick = useCallback(() => reset(), [reset]);
	return (
		<Button startIcon={icon} size="medium" variant="outlined" color="secondary" {...props} onClick={handleClick} />
	);
});

ResetButton.defaultProps = {
	icon: <ReplayIcon />,
	label: 'Reset'
};
