import { get } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useCallbackRef } from '../../hooks/useCurrentCallback';

export const useFormOnValueChange = onValueChange => {
	const { setValue, watch } = useFormContext();

	const setValueRef = useCallbackRef(setValue);

	const setter = useCallback(
		(...args) => setValueRef.current?.(...args) ?? undefined,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	useEffect(
		() =>
			onValueChange
				? watch((data, { name, type }) => {
						if (!name || !type) return;
						if (typeof onValueChange === 'function') onValueChange({ setValue: setter, data, name, type });
						else if (onValueChange) {
							let handler = get(onValueChange, name) ?? onValueChange?.[name];
							if (typeof handler === 'function') handler({ setValue: setter, data, name, type });
							else {
								handler = get(onValueChange, '...') ?? onValueChange?.['...'];
								if (typeof handler === 'function') handler({ setValue: setter, data, name, type });
							}
							handler = get(onValueChange, '*') ?? onValueChange?.['*'];
							if (typeof handler === 'function') handler({ setValue: setter, data, name, type });
						}
				  }).unsubscribe
				: undefined,
		[onValueChange, setter, watch]
	);
};
