import { DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { FileField, FileInput, ImageField, ImageInput } from 'react-admin';
import { WdForm } from '.';
import { FormToolbar } from './toolbar';

const saveOpts = { label: 'Upload' };

export const FileUploadForm = ({ onCancel, inputProps, ...props }) => {
	const cancelOpts = useMemo(() => ({ onClick: onCancel }), [onCancel]);

	return (
		<WdForm {...props}>
			<DialogContent>
				<FileUploadInput {...inputProps} source="file" />
			</DialogContent>
			<FormToolbar cancel saveOpts={saveOpts} cancelOpts={cancelOpts} />
		</WdForm>
	);
};

FileUploadForm.propTypes = {
	...WdForm.propTypes,
	inputProps: PropTypes.oneOfType([PropTypes.shape(ImageInput.propTypes), PropTypes.shape(FileInput.propTypes)]),
	onCancel: PropTypes.func
};

export default FileUploadForm;

const FileUploadInput = ({ accept, children, ...props }) => {
	const acceptStr = useMemo(() => {
		if (!accept) return;
		if (typeof accept === 'string') return accept;
		if (Array.isArray(accept)) return accept.join(',');
		console.warn('Accept is not an array', accept);
	}, [accept]);

	if (
		(typeof accept === 'string' && accept.startsWith('image/')) ||
		(Array.isArray(accept) && accept.length === 1 && accept[0].startsWith('image/'))
	)
		return (
			<ImageInput {...props} accept={acceptStr} label={false}>
				<ImageField source="src" title="title" />
			</ImageInput>
		);
	return (
		<FileInput {...props} accept={acceptStr} label={false}>
			<FileField source="src" title="title" />
		</FileInput>
	);
};
