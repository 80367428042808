import { COUNTRIES, INDUSTRIES } from '@extend/paywall-api/lib/resource-types';
import { CardContent, Stack } from '@mui/material';
import { useMemo } from 'react';
import {
	AutocompleteArrayInput,
	AutocompleteInput,
	BooleanInput,
	Form,
	FormDataConsumer,
	RadioButtonGroupInput,
	ReferenceArrayInput,
	ReferenceInput,
	TextInput,
	useCreateContext
} from 'react-admin';
import { UploadedImageField } from '../../../components/fields/uploaded-image-field';
import { FormToolbar } from '../../../components/form/toolbar';
import { ReferenceFileInput } from '../../../components/inputs/reference-image-input';
import { getDisabled } from '../../../utils/form-inputs';
import { custTypes } from '../types';
import { validateForm } from './validate';

export const CustomerForm = ({ previewImages }) => {
	const { save: create } = useCreateContext();

	const emailInputProps = useMemo(() => ({ readOnly: getDisabled(!create) }), [create]);

	return (
		<Form validate={validateForm}>
			<CardContent>
				<Stack direction="row" spacing={4}>
					<Stack spacing={0} flex="3 3 0">
						<TextInput source="email" label="Email" InputProps={emailInputProps} autoFocus />
						<BooleanInput
							source="is_counted"
							label="Count user in statistics and notifications"
							defaultValue={false}
						/>
						<BooleanInput source="auto_renewal" label="Auto renew subscription" defaultValue={true} />
						<BooleanInput
							source="report_pdf_download"
							label="Allow single file download"
							defaultValue={true}
							helperText="Allows downloading full report slides or wisdrop slides in a single pdf file"
						/>
						<RadioButtonGroupInput
							optionText="name"
							label={'Customer Type'}
							source="customer_type"
							defaultValue="Business"
							choices={custTypes}
						/>
						<ReferenceInput
							perPage={1000}
							source="industry_id"
							label="Industry"
							reference={INDUSTRIES}
							sort={{ field: 'title' }}>
							<AutocompleteInput optionText="title" label="Industry" />
						</ReferenceInput>
						<FormDataConsumer>
							{({ formData: { customer_type } }) => (
								<>
									<TextInput source="name" label={customer_type === 'Business' ? 'Full Name' : 'Name'} />
									<TextInput
										source="last_name"
										label={customer_type === 'Business' ? 'Company Name' : 'Last Name'}
									/>
								</>
							)}
						</FormDataConsumer>
						<ReferenceFileInput label="Client Logo" source="logo_id" allowEmpty>
							<UploadedImageField
								options={{ type: 'medium', width: 128, height: 96 }}
								sx={{ aspectRatio: '16/5', width: '128px', backgroundSize: 'contain' }}
							/>
						</ReferenceFileInput>
					</Stack>
					<Stack spacing={0} flex="4 4 0">
						<ReferenceArrayInput
							source="available_in_country_ids"
							perPage={1000}
							reference={COUNTRIES}
							filter={{ with_content: true }}
							sort={{ field: 'name' }}>
							<AutocompleteArrayInput
								optionText="name"
								label="Accessible Content Countries"
								fullWidth
								helperText="Content access is restricted to the selected countries. Leave empty to enable access to every country's content"
							/>
						</ReferenceArrayInput>
						<FormDataConsumer>
							{({ formData: { customer_type } }) =>
								customer_type === 'Business' ? (
									<>
										<TextInput source="afm" label="AFM" />
										<TextInput source="doy" label="DOY" />
										<TextInput source="job_description" label="Job" />
									</>
								) : null
							}
						</FormDataConsumer>
						<TextInput source="email_domains" label="Accepted email extensions" />
						<TextInput source="email_whitelist" label="Allow access to these emails" />
					</Stack>
				</Stack>
			</CardContent>
			<FormToolbar cancel reset={!create} />
		</Form>
	);
};
