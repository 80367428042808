import { Suspense, useMemo } from 'react';
import { Loading } from 'react-admin';
import { FileCreator } from '.';
import FileUploadForm from '../../components/form/file-upload-form';
import { WdDialog, WdDialogTitle } from '../../components/wisdrop-dialog';

export const FileUploadDialog = ({ onClose, open, inputProps, title, mutationOptions, ...props }) => {
	const onCancel = useMemo(
		() =>
			onClose
				? e => {
						e.preventDefault();
						onClose();
				  }
				: undefined,
		[onClose]
	);
	return (
		<WdDialog onClose={onClose} open={open} {...props}>
			<WdDialogTitle title={title || 'Upload'} />
			<Suspense fallback={<Loading />}>
				<FileCreator mutationOptions={mutationOptions}>
					<FileUploadForm inputProps={inputProps} onCancel={onCancel} />
				</FileCreator>
			</Suspense>
		</WdDialog>
	);
};

FileUploadDialog.defaultProps = { maxWidth: 'sm', fullWidth: true, redirect: false };
