import { StatsApi } from '@extend/paywall-api-javascript';
import { getApiClient } from '../../api/client';
import { overrides } from '../../utils/override';
import { to_ra_data_result } from '../../utils/result-converters';

const mediaStatsProvider = {
	getMany: ({ ids }) => {
		if (ids.length === 0) return Promise.resolve({ data: [] });
		const [, customer_id] = overrides.destructureId(ids[0]);
		const mapper = overrides.createMapper(customer_id, 'id');
		var api = new StatsApi(getApiClient());
		return api
			.getFullReport({
				FullReportStatsQuery: {
					filter: { media_ids: ids.map(id => overrides.destructureId(id)[0]), customer_id }
				}
			})
			.then(mapper)
			.then(to_ra_data_result);
	},
	getOne: ({ id }) => {
		const [mediaId, customer_id] = overrides.destructureId(id);
		const mapper = overrides.createMapper(customer_id, 'id');
		var api = new StatsApi(getApiClient());
		return api
			.getFullReport({ FullReportStatsQuery: { filter: { media_ids: [mediaId], customer_id } } })
			.then(mediaList => (mediaList.length === 0 ? null : mediaList[0]))
			.then(mapper)
			.then(to_ra_data_result);
	}
};

export default mediaStatsProvider;
