import get from 'lodash/get';
import { memo } from 'react';
import { BooleanField, useRecordContext } from 'react-admin';

export const OnlyTrueBooleanField = memo(({ source, label, record: inRecord, falseElement, ...props }) => {
	const record = useRecordContext({ record: inRecord });
	return record && source && Boolean(get(record, source)) ? (
		<BooleanField {...props} source={source} record={record} />
	) : (
		falseElement || null
	);
});
