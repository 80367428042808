import IconUnblock from '@mui/icons-material/BackHand';
import IconBlock from '@mui/icons-material/DoNotTouch';
import { useMemo } from 'react';
import { useNotify, useRecordContext, useResourceContext } from 'react-admin';
import { useQueryClient } from 'react-query';
import { ResourceActionButton } from '../../components/resource-action-button';
import { getDisabled } from '../../utils/form-inputs';

export const SuspendUnsuspendButton = ({
	record: inRecord,
	resource: inResource,
	disabled,
	suspendedMessage,
	suspendedFailedMessage,
	unSuspendedMessage,
	unSuspendedFailedMessage,
	...props
}) => {
	const queryClient = useQueryClient();
	const notify = useNotify();
	const resource = useResourceContext({ resource: inResource });
	const record = useRecordContext({ record: inRecord });
	const isSuspended = record?.is_suspended;
	const calcProps = useMemo(
		() =>
			isSuspended == null
				? null
				: isSuspended === true
				? {
						sx: { 'padding': 0, 'minWidth': 0, '& .MuiButton-startIcon': { margin: 0 } },
						action: 'unsuspend',
						label: null,
						color: 'info',
						icon: <IconUnblock />,
						tooltip: 'Unsuspend',
						mutationOptions: {
							onSuccess: () => {
								notify(unSuspendedMessage ?? 'Unsuspended', { type: 'info' });
								queryClient.invalidateQueries([resource]);
							},
							onError: () => notify(unSuspendedFailedMessage ?? 'Unable to unsuspend', { type: 'warning' })
						}
				  }
				: {
						sx: { 'padding': 0, 'minWidth': 0, '& .MuiButton-startIcon': { margin: 0 } },
						action: 'suspend',
						label: null,
						color: 'primary',
						icon: <IconBlock />,
						tooltip: 'Suspend',
						mutationOptions: {
							onSuccess: () => {
								notify(suspendedMessage ?? 'Suspended', { type: 'info' });
								queryClient.invalidateQueries([resource]);
							},
							onError: () => notify(suspendedFailedMessage ?? 'Unable to suspend', { type: 'warning' })
						}
				  },
		[
			isSuspended,
			notify,
			queryClient,
			resource,
			suspendedFailedMessage,
			suspendedMessage,
			unSuspendedFailedMessage,
			unSuspendedMessage
		]
	);

	return (
		<ResourceActionButton
			resource={resource}
			record={record}
			disabled={getDisabled(!record || disabled)}
			{...calcProps}
			variant="text"
			size="small"
			{...props}
		/>
	);
};
