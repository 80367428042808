import { ContactsApi, UsersApi } from '@extend/paywall-api-javascript';
import { getApiClient } from '../../api/client';
import {
	from_ra_query,
	prepareForPost,
	throwErrors,
	to_ra_data_result,
	to_ra_query_result
} from '../../utils/result-converters';

const contacsProvider = {
	getList: params =>
		new ContactsApi(getApiClient()).query({ CustomerContactQuery: from_ra_query(params) }).then(to_ra_query_result),

	getMany: ({ ids }) => new ContactsApi(getApiClient()).getMany({ request_body: ids }).then(to_ra_data_result),

	getOne: ({ id }) => new ContactsApi(getApiClient()).get(id).then(to_ra_data_result),

	getManyReference: params => {
		const { target, id, ...rest } = params;
		if (target === 'customer_id')
			return new ContactsApi(getApiClient())
				.query({
					CustomerContactQuery: from_ra_query({ ...rest, filter: { ...(rest.filter || {}), customer_id: id } })
				})
				.then(to_ra_query_result);
		return null;
	},

	create: ({ data }) =>
		new ContactsApi(getApiClient())
			.create({ CreateCustomerContactCommand: prepareForPost(data) })
			.then(to_ra_data_result)
			.catch(throwErrors),

	update: params =>
		new ContactsApi(getApiClient())
			.update(params.id, {
				UpdateCustomerContactCommand: prepareForPost({
					...params.previousData,
					...params.data
				})
			})
			.then(to_ra_data_result)
			.catch(throwErrors),

	delete: ({ id }) => new ContactsApi(getApiClient()).remove(id).then(to_ra_data_result).catch(throwErrors),

	deleteMany: ({ ids }) => new ContactsApi(getApiClient()).removeMany(ids).then(to_ra_data_result).catch(throwErrors),

	resendActivationEmail: user_id =>
		new UsersApi(getApiClient()).resendActivationEmail(user_id).then(to_ra_data_result).catch(throwErrors),

	suspend: ({ id }) => {
		var api = new ContactsApi(getApiClient());
		return api.suspendMany([id]).then(to_ra_data_result).catch(throwErrors);
	},
	unsuspend: ({ id }) => {
		var api = new ContactsApi(getApiClient());
		return api.unsuspendMany([id]).then(to_ra_data_result).catch(throwErrors);
	}
};

export default contacsProvider;
