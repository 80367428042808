import { DialogContent, Stack } from '@mui/material';
import { useMemo } from 'react';
import { BooleanInput, Form, LinearProgress, TextInput, useCreateContext } from 'react-admin';
import { FormToolbar } from '../../components/form/toolbar';
import { getDisabled } from '../../utils/form-inputs';
import validate from './validation';

const ContactsModalForm = ({ customer, onClose }) => {
	const { save: create } = useCreateContext();
	const isCreate = !!create;
	const cancelProps = useMemo(
		() => ({
			onClick: onClose
				? e => {
						e.preventDefault();
						onClose();
				  }
				: undefined
		}),
		[onClose]
	);
	const emailInputProps = useMemo(() => ({ readOnly: getDisabled(!create) }), [create]);
	return customer ? (
		<Form defaultValues={{ customer_id: customer.id, is_counted: true }} validate={validate}>
			<DialogContent>
				<Stack spacing={0}>
					<TextInput source="email" label="Email" autoFocus={isCreate} InputProps={emailInputProps} />
					<TextInput source="name" label="Name" autoFocus={!isCreate} />
					<TextInput source="last_name" label="Last Name" />
					<TextInput source="job_title" label="Title" />
					<BooleanInput source="is_admin" label="Administrator rights" />
					<BooleanInput source="is_suspended" label="Suspended" />
					<BooleanInput source="is_counted" label="Count user in statistics and notifications" />
				</Stack>
			</DialogContent>
			<FormToolbar cancel cancelOpts={cancelProps} />
		</Form>
	) : (
		<LinearProgress />
	);
};
export default ContactsModalForm;
