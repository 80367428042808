import { CategoriesApi } from '@extend/paywall-api-javascript';
import { getApiClient } from '../../api/client';
import {
	from_ra_query,
	prepareForPost,
	throwErrors,
	to_ra_data_result,
	to_ra_query_result
} from '../../utils/result-converters';

const categoriesProvider = {
	getList: params =>
		new CategoriesApi(getApiClient()).query({ CategoryQuery: from_ra_query(params) }).then(to_ra_query_result),
	getMany: ({ ids }) => new CategoriesApi(getApiClient()).getMany({ request_body: ids }).then(to_ra_data_result),
	getOne: ({ id }) => new CategoriesApi(getApiClient()).get(id).then(to_ra_data_result),
	create: ({ data }) =>
		new CategoriesApi(getApiClient())
			.create({ CreateCategoryCommand: prepareForPost(data) })
			.then(to_ra_data_result)
			.catch(throwErrors),
	update: ({ previousData, data, id }) =>
		new CategoriesApi(getApiClient())
			.update(id, { UpdateCategoryCommand: prepareForPost({ ...previousData, ...data }) })
			.then(to_ra_data_result)
			.catch(throwErrors),
	delete: ({ id }) => new CategoriesApi(getApiClient()).remove(id).then(to_ra_data_result).catch(throwErrors),
	deleteMany: ({ ids }) =>
		new CategoriesApi(getApiClient()).removeMany(ids).then(to_ra_data_result).catch(throwErrors),
	getManyReference: ({ id, target, filter, ...restParams }) => {
		var actualParams = {
			...restParams,
			filter: {
				...filter,
				[target]: id
			}
		};

		return new CategoriesApi(getApiClient())
			.query({ CategoryQuery: from_ra_query(actualParams) })
			.then(to_ra_query_result);
	}
};

export default categoriesProvider;
