import { Suspense } from 'react';
import { Layout } from 'react-admin';
import { WdAppBar } from './app-bar';
import { DelayedLoading } from './components/loading-with-delay';

export const WisdropLayout = ({ children, ...props }) => (
	<Layout {...props} appBar={WdAppBar}>
		<Suspense fallback={<DelayedLoading delay={300} />}>{children}</Suspense>
	</Layout>
);
