import { useMemo } from 'react';
import { useGetOne } from 'react-admin';
import { BLOBS } from '../resources/blobs/name';

export const useGetAuthImageUrl = (url, options) => {
	const finalUrl = useMemo(() => {
		if (!url) return null;
		const params = new URLSearchParams({
			thumbnail: options?.type,
			w: options?.width,
			h: options?.height
		}).toString();
		if (params) return `${url}?${params}`;
		return url;
	}, [options?.height, options?.type, options?.width, url]);
	return useGetOne(BLOBS, { id: finalUrl }, { enabled: !!finalUrl, keepPreviousData: true });
};
