import downloadFile from 'downloadjs';
import { useCallback } from 'react';
import { getApiBasePath, nativeDownloadFiles } from '../api/client';

export const useDownloadFile = ({ url, mimeType, parameters, fileName }) =>
	useCallback(async () => {
		try {
			const { blob, fileName: returnedFileName } = await nativeDownloadFiles({
				apiBasePath: getApiBasePath(),
				endpoint: url,
				method: 'POST',
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json',
					'Accept': mimeType
				},
				body: JSON.stringify(parameters)
			});
			downloadFile(blob, fileName || returnedFileName, mimeType);
		} catch (e) {
			console.error(e);
		}
	}, [parameters, fileName, mimeType, url]);
