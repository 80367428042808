import { BatchUploadApi } from '@extend/paywall-api-javascript';
import { getApiClient, uploadFiles } from '../api/client';

export const uploadFilesToBatch = async ({ files, progressReset, progressUpdate }) => {
	var total = files.length;

	var filesDict = {};

	for (var i = 0; i < total; ++i) {
		var fname = files[i].rawFile.name;
		filesDict[fname] = { name: fname };
	}

	var batchApi = new BatchUploadApi(getApiClient());

	progressReset(total);

	var { batch_id } = await batchApi.create({ filesInfo: filesDict });

	var endpoint = `/api/v1/BatchUpload/${batch_id}`;

	for (i = 0; i < total; ++i) {
		var file = files[i].rawFile;
		await uploadFiles({ endpoint, method: 'PUT', fileProp: 'files', files: [file] });
		progressUpdate();
	}

	return batch_id;
};

export const alphanumericCollator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
