import { USERS } from '@extend/paywall-api/lib/resource-types';
import IconGroup from '@mui/icons-material/Group';
import { lazy } from 'react';
import { Resource } from 'react-admin';

const UsersIndex = lazy(() => import(/* webpackChunkName: "users-list" */ './list.js'));
const UserCreator = lazy(() => import(/* webpackChunkName: "users-creator" */ './creator.js'));

const getUsersResource = perms => (
	<Resource
		key={USERS}
		name={USERS}
		options={{ label: 'System Users' }}
		list={UsersIndex}
		create={UserCreator}
		icon={IconGroup}
	/>
);
export default getUsersResource;
