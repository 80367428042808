import { AuthApi } from '@extend/paywall-api-javascript';
import { HttpError } from 'react-admin';
import { getApiClient } from '../api/client';
import raStore from '../app-store';
import { BlobCache } from '../utils/blob-cache_';
import { getError } from '../utils/errors';

export const TOKEN_KEY = 'auth-token';
export const USER_KEY = 'user-info';
export const PERMS_KEY = 'permissions';

const WD_ADMIN_BROWSER_ID = 'WISDROP-99288499603409823094802342348098423-ADMIN'; // Do not change this

class AuthProvider {
	constructor() {
		const tokenHolder = { token: raStore.getItem(TOKEN_KEY) };

		this.setToken = token => {
			tokenHolder.token = token;
			if (token) raStore.setItem(TOKEN_KEY, token);
			else raStore.removeItem(TOKEN_KEY);
		};

		this.getAuthToken = () => tokenHolder.token;
	}

	login = async ({ username, password } = {}) => {
		BlobCache.clearAllBlobs();
		if (!username || !password) throw new HttpError('Please provide username and password', 403);
		var api = new AuthApi(getApiClient());
		var {
			authToken: token,
			user,
			permissions
		} = await api.adminLogin({ email: username, password: password, browser_id: WD_ADMIN_BROWSER_ID });
		this.setToken(token);
		raStore.setItem(USER_KEY, user);
		raStore.setItem(PERMS_KEY, permissions);
	};

	checkError = error => {
		if (error.status === 401 || error.status === 403)
			return Promise.reject(getError(error.status, error.message || 'Unauthorized'));
		return Promise.resolve();
	};

	checkAuth = () => (this.getAuthToken() ? Promise.resolve() : Promise.reject());

	logout = () => {
		this.setToken(null);
		raStore.removeItem(USER_KEY);
		raStore.removeItem(PERMS_KEY);
		BlobCache.clearAllBlobs();
		return Promise.resolve();
	};

	getIdentity = () => {
		if (!this.getAuthToken()) return Promise.reject();
		var { id, email: fullName } = raStore.getItem(USER_KEY);
		return Promise.resolve({ id, fullName });
	};

	getPermissions = () => Promise.resolve(raStore.getItem(PERMS_KEY) || {});
}

const authProvider = new AuthProvider();

export default authProvider;
