const validate = values => {
	var errors = {};
	['customer_id', 'subscription_type_id', 'valid_from', 'valid_to', 'payment_mode'].forEach(field => {
		const val = values[field];
		if (val == null || val === '') errors[field] = 'Required';
	});
	const { valid_from, valid_to } = values;
	if (!!valid_from && !!valid_to && valid_to < valid_from) errors.valid_to = 'Valid to must be after valid from';
	return errors;
};

export default validate;
