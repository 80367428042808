import { RolesApi } from '@extend/paywall-api-javascript';
import { getApiClient } from '../../api/client';
import { from_ra_query, to_ra_data_result, to_ra_query_result } from '../../utils/result-converters';

const rolesProvider = {
	getList: params => new RolesApi(getApiClient()).query({ RoleQuery: from_ra_query(params) }).then(to_ra_query_result),
	getMany: ({ ids }) => new RolesApi(getApiClient()).getMany({ DefinedUserRoles: ids }).then(to_ra_data_result),
	getOne: ({ id }) => new RolesApi(getApiClient()).get(id).then(to_ra_data_result)
};

export default rolesProvider;
