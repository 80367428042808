import { AdScoreSlidesApi } from '@extend/paywall-api-javascript';
import { getApi } from '../../api/client';
import { from_ra_query, prepareForPost, to_ra_data_result, to_ra_query_result } from '../../utils/result-converters';

const adScoreSlidesProvider = {
	getList: params =>
		getApi(AdScoreSlidesApi)
			.query({ AdScoreSlideQuery: from_ra_query(params) })
			.then(to_ra_query_result),

	getManyReference: params => {
		var { id, target, filter, ...restParams } = params;

		var actualParams = {
			...restParams,
			filter: {
				...filter,
				[target]: id
			}
		};

		return getApi(AdScoreSlidesApi)
			.query({ AdScoreSlideQuery: from_ra_query(actualParams) })
			.then(to_ra_query_result);
	},

	getMany: params => getApi(AdScoreSlidesApi).getMany({ request_body: params.ids }).then(to_ra_data_result),

	getOne: params => getApi(AdScoreSlidesApi).get(params.id).then(to_ra_data_result),

	create: params =>
		getApi(AdScoreSlidesApi)
			.create({
				CommandAddAdScoreSlide: prepareForPost(params.data)
			})
			.then(to_ra_data_result),

	update: params =>
		getApi(AdScoreSlidesApi)
			.update(params.id, {
				CommandUpdateAdScoreSlide: prepareForPost({ ...params.previousData, ...params.data })
			})
			.then(to_ra_data_result),

	delete: params => getApi(AdScoreSlidesApi).remove(params.id).then(to_ra_data_result),

	deleteMany: params => getApi(AdScoreSlidesApi).removeMany(params.ids).then(to_ra_data_result)
};

export default adScoreSlidesProvider;
