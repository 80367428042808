import { CustomersApi, SubscriptionsApi } from '@extend/paywall-api-javascript';
import { getApiClient } from '../../api/client';
import {
	from_ra_query,
	prepareForPost,
	throwErrors,
	to_ra_data_result,
	to_ra_query_result
} from '../../utils/result-converters';

export const subscriptionsProvider = {
	getOne: ({ id }) => new SubscriptionsApi(getApiClient()).get(id).then(to_ra_data_result),
	getMany: ({ ids }) => new SubscriptionsApi(getApiClient()).getMany({ request_body: ids }).then(to_ra_data_result),
	getManyReference: ({ target, id, ...params }) => {
		switch (target) {
			case 'customer_id':
				return new CustomersApi(getApiClient())
					.querySubscriptions(id, { SubscriptionQuery: from_ra_query(params) })
					.then(to_ra_query_result);
			default:
				return null;
		}
	},
	create: ({ data }) =>
		new SubscriptionsApi(getApiClient())
			.create({ CreateCustomerSubscriptionCommand: prepareForPost(data) })
			.then(to_ra_data_result)
			.catch(throwErrors),
	update: ({ previousData, data, id }) =>
		new SubscriptionsApi(getApiClient())
			.update(id, {
				UpdateCustomerSubscriptionCommand: prepareForPost({ ...previousData, ...data })
			})
			.then(to_ra_data_result)
			.catch(throwErrors),
	cancel: ({ id }) => new SubscriptionsApi(getApiClient()).cancel(id).then(to_ra_data_result).catch(throwErrors),
	undoCancel: ({ id }) =>
		new SubscriptionsApi(getApiClient()).undoCancel(id).then(to_ra_data_result).catch(throwErrors)
};

export default subscriptionsProvider;
