import { DialogContentText, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { TextField, useRecordContext } from 'react-admin';
import { CustomActionColumn } from '../../components/custom-action-column';
import { OnlyTrueBooleanField } from '../../components/only-true-boolean-field';
import { WdDataGrid } from '../../components/wisdrop-data-grid';
import { customerDisplayName } from '../customers/utils/display-name';
import { getAddressDisplayText } from './utils';

const AddressesGrid = props => {
	const customer = useRecordContext();
	const deleteProps = useMemo(
		() => ({
			redirect: false,
			confirmTitle: customer
				? `Deleting address of customer ${customerDisplayName(customer)}`
				: 'Deleting address of customer',
			confirmContent: record => <DeleteAddressContent customer={customer} />
		}),
		[customer]
	);
	return (
		<WdDataGrid rowClick="edit" bulkActionButtons={false} {...props}>
			<TextField source="address" label="Address" />
			<TextField source="region" label="Region" />
			<TextField source="postal_code" label="Postal Code" />
			<OnlyTrueBooleanField source="is_billing" label="Is Billing" />
			<OnlyTrueBooleanField source="is_delivery" label="Is Delivery" />
			<OnlyTrueBooleanField source="is_default" label="Is Default" />
			<TextField source="description" label="Description" />
			<CustomActionColumn showDelete showEdit={false} deleteProps={deleteProps} />
		</WdDataGrid>
	);
};

const DeleteAddressContent = () => {
	const address = useRecordContext();
	const addressText = useMemo(() => getAddressDisplayText(address), [address]);
	if (!addressText) return null;
	return (
		<Stack spacing={2}>
			<Typography variant="body1" component="p">
				Are you sure you want to delete this address?
			</Typography>
			<DialogContentText component="p">{addressText}</DialogContentText>
		</Stack>
	);
};

export default AddressesGrid;
