import { SubscriptionTypesApi } from '@extend/paywall-api-javascript';
import { getApiClient } from '../../api/client';
import {
	from_ra_query,
	prepareForPost,
	throwErrors,
	to_ra_data_result,
	to_ra_query_result
} from '../../utils/result-converters';

const subscTypeProvider = {
	getList: params =>
		new SubscriptionTypesApi(getApiClient())
			.query({ SubscriptionTypeQuery: from_ra_query(params) })
			.then(to_ra_query_result),
	getMany: ({ ids }) =>
		new SubscriptionTypesApi(getApiClient()).getMany({ request_body: ids }).then(to_ra_data_result),
	getOne: ({ id }) => new SubscriptionTypesApi(getApiClient()).get(id).then(to_ra_data_result),
	create: ({ data }) =>
		new SubscriptionTypesApi(getApiClient())
			.create({ CreateSubscriptionTypeCommand: prepareForPost(data) })
			.then(to_ra_data_result)
			.catch(throwErrors),
	update: ({ previousData, data, id }) =>
		new SubscriptionTypesApi(getApiClient())
			.update(id, {
				UpdateSubscriptionTypeCommand: prepareForPost({
					...previousData,
					...data
				})
			})
			.then(to_ra_data_result)
			.catch(throwErrors)
};

export default subscTypeProvider;
