import { useEffect, useState } from 'react';
import { Loading } from 'react-admin';

export const DelayedLoading = ({ delay }) => {
	const [visible, setVisible] = useState(false);
	useEffect(() => {
		const to = setTimeout(setVisible(true), delay);
		return () => clearTimeout(to);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return visible ? <Loading /> : null;
};
