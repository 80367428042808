import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { AppBar } from 'react-admin';
import { ReactComponent as WisdropLogoIcon } from './assets/images/w.svg';

const WdLogo = props => <WisdropLogoIcon {...props} viewBox="0 0 475 475" width="40" height="40" />;

export const WdAppBar = props => (
	<AppBar {...props}>
		<Typography variant="h6" color="inherit" className="RaAppBar-title" id="react-admin-title" />
		<Box sx={{ flexGrow: 1 }} id="app-bar-spacer" />
		<Box
			className="WdLogo-icon"
			sx={{
				zIndex: -1,
				position: 'absolute',
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
				display: 'flex',
				justifyContent: 'center',
				maringRight: 2
			}}>
			<WdLogo sx={{ zIndex: 100 }} />
		</Box>
	</AppBar>
);
