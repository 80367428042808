import { Dialog, DialogTitle, Slide, styled } from '@mui/material';
import { forwardRef, memo, useCallback } from 'react';

export const WdDialog = ({ onClose, ...props }) => {
	const handleClose = useCallback(
		(e, reason) => {
			if (reason === 'backdropClick') return;
			onClose();
		},
		[onClose]
	);
	return <StyledDialog {...props} onClose={handleClose} />;
};

const StyledDialog = styled(Dialog, {
	name: 'WdDialog',
	slot: 'Root',
	overridesResolver: (props, styles) => styles.root
})({
	'& .MuiDialogTitle-root': { paddingBottom: 0 }
});

export const WdDialogTitle = memo(({ title, ...props }) =>
	title ? <DialogTitle {...props}>{title}</DialogTitle> : null
);

const SlideUpTransition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

WdDialog.defaultProps = { keepMounted: false, TransitionComponent: SlideUpTransition };
WdDialog.propTypes = {
	...Dialog.propTypes
};
