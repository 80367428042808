import { SUBSCRIPTION_TYPES } from '@extend/paywall-api/lib/resource-types';
import { alpha, Chip } from '@mui/material';
import { red } from '@mui/material/colors';
import { useMemo } from 'react';
import { DateField, NumberField, ReferenceField, SelectField, TextField, useRecordContext } from 'react-admin';
import { CustomActionColumn } from '../../components/custom-action-column';
import { OnlyTrueBooleanField } from '../../components/only-true-boolean-field';
import { WdDataGrid } from '../../components/wisdrop-data-grid';
import { SubscriptionCancellationButton } from './cancel';
import { paymentModeChoices } from './payment-modes';

const getRowStyle = record => {
	if (record.is_active) return { backgroundColor: alpha(red[500], 0.1) };
	return {};
};

const SubscriptionsGrid = props => {
	return (
		<WdDataGrid rowClick="edit" bulkActionButtons={false} rowStyle={getRowStyle} {...props}>
			<NumberField source="subscription_number" label="AA" cellClassName="no-wrap" headerClassName="no-wrap" />
			<ReferenceField
				source="subscription_type_id"
				reference={SUBSCRIPTION_TYPES}
				label={'Subscr. Plan'}
				link={false}
				cellClassName="no-wrap"
				headerClassName="no-wrap">
				<TextField source="title" />
			</ReferenceField>
			<SelectField
				source="payment_mode"
				optionText="text"
				choices={paymentModeChoices}
				cellClassName="no-wrap"
				headerClassName="no-wrap"
			/>
			<DateField
				source="valid_from"
				label={'Valid from'}
				locales="en-US"
				options={{ year: 'numeric', month: 'short', day: 'numeric' }}
				cellClassName="no-wrap"
				headerClassName="no-wrap"
			/>
			<DateField
				source="valid_to"
				label={'Valid to'}
				locales="en-US"
				options={{ year: 'numeric', month: 'short', day: 'numeric' }}
				cellClassName="no-wrap"
				headerClassName="no-wrap"
			/>
			<SubscriptionStatusField label={'Status'} cellClassName="no-wrap" headerClassName="no-wrap" />
			<OnlyTrueBooleanField source="is_paid" label={'Paid'} headerClassName="no-wrap" />
			<NumberField
				source="paid_amount"
				label="Paid Amount"
				locales="el-GR"
				options={{ maximumFractionDigits: 2, style: 'currency', currency: 'EUR' }}
				cellClassName="no-wrap"
				headerClassName="no-wrap"
			/>
			<TextField source="description" label={'Description'} headerClassName="no-wrap" />
			<CustomActionColumn showEdit={false} cellClassName="no-wrap" headerClassName="no-wrap">
				<SubscriptionCancellationButton />
			</CustomActionColumn>
		</WdDataGrid>
	);
};

const SubscriptionStatusField = () => {
	const record = useRecordContext();
	const hasRecord = !!record;
	const { is_active, is_expired, is_cancelled } = record || {};
	return useMemo(() => {
		if (!hasRecord) return null;
		if (is_cancelled) return <Chip color="error" label="Canceled" size="small" disabled />;
		if (is_expired) return <Chip color="default" label="Expired" size="small" />;
		if (is_active) return <Chip color="info" label="Active" size="small" />;
		return <Chip color="default" label="Future" size="small" disabled />;
	}, [hasRecord, is_active, is_cancelled, is_expired]);
};

export default SubscriptionsGrid;
