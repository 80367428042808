import { Box, styled } from '@mui/material';
import PropTypes from 'prop-types';
import clsx from 'clsx';

export const BackgroundImage = styled(
	({ className, ...props }) => <Box {...props} className={clsx('WdBackgroundImage-root', className)} />,
	{
		name: 'WdBackgroundImage',
		slot: 'Root',
		overridesResolver: (props, styles) => styles.root,
		shouldForwardProp: name => name !== 'url'
	}
)(({ url }) => ({
	backgroundImage: !!url ? `url('${url}')` : undefined,
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover'
}));
BackgroundImage.propTypes = {
	...Box.propTypes,
	url: PropTypes.string
};
