import { Tooltip } from '@mui/material';
import { useCallback } from 'react';
import { Button } from 'react-admin';
import { useResourceAction } from '../hooks/use-resource-action';
import { getDisabled } from '../utils/form-inputs';

export const ResourceActionButton = ({
	resource: inResource,
	record: inRecord,
	action,
	payload: payloadAccessor,
	onClick,
	disabled,
	mutationOptions,
	icon,
	tooltip,
	...props
}) => {
	const { mutate, isLoading } = useResourceAction({
		resource: inResource,
		record: inRecord,
		action,
		payload: payloadAccessor,
		mutationOptions
	});

	const handleClick = useCallback(
		e => {
			onClick?.(e);
			if (e.defaultPrevented) return;
			e.stopPropagation();
			mutate?.();
		},
		[mutate, onClick]
	);

	const content = (
		<Button {...props} onClick={handleClick} disabled={getDisabled(disabled || isLoading)}>
			{icon}
		</Button>
	);

	if (tooltip)
		return (
			<Tooltip title={tooltip} placement="top">
				<div>{content}</div>
			</Tooltip>
		);

	return content;
};

// eslint-disable-next-line react/forbid-foreign-prop-types
const { onClick, ...restPropTypes } = Button.propTypes;
ResourceActionButton.propTypes = {
	...restPropTypes
};
