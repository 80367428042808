const idGetter = (entityId, customerId) => (customerId ? `${entityId}|${customerId}` : entityId);
const idDestructurer = id => {
	const res = id.split('|');
	return [res.length > 0 ? res[0] : undefined, res.length > 1 ? res[1] : undefined];
};

const _createMapper = (customerId, entityIdProp) => data => {
	if (Array.isArray(data)) return data.map(r => ({ ...r, id: overrides.createId(r[entityIdProp], customerId) }));
	if (typeof data === 'object') return { ...data, id: overrides.createId(data[entityIdProp], customerId) };
	return data;
};

/** Overrides Accessibility Utilities */
export const overrides = {
	/** Creates an override accessibility id from the combination of entityId and customerId
	 * const id = createId(entityId, customerId)
	 */
	get createId() {
		return idGetter;
	},

	/** Destructures an override accessibility id and returns the entityId and customerId as an array of 2 elements. The first is the entityId and the second is the customerId
	 * const [entityId, customerId] = destructureId(id);
	 */
	get destructureId() {
		return idDestructurer;
	},

	/** Creates a mapper that will combine the ids returned from the server with the given customer id to create records with a unique overrideId */
	get createMapper() {
		return _createMapper;
	}
};
