import { Card } from '@mui/material';
import { CreateBase, Title } from 'react-admin';
import { AdScoreForm } from './form';

export const AdScoreCreator = () => (
	<Card sx={{ marginTop: 2 }}>
		<Title title="Add new Ad Score" />
		<CreateBase redirect="edit" mutationMode="pessimistic">
			<AdScoreForm />
		</CreateBase>
	</Card>
);
export default AdScoreCreator;
